import React, { useState } from "react";
import { ImageUploadButton } from "./ImageUploadButton";
import { Image } from "./Image";
import { P } from "common/P/P";

interface IPropsImageUpload {
	slider?: boolean;
	singleUpload?: boolean;
	onImageUpload?: (imageSrc: string) => void;
	label?: string;
}

const ImageUpload: React.FC<IPropsImageUpload> = ({
	slider,
	singleUpload = false,
	onImageUpload,
	label = "Picture",
}) => {
	const [images, setImages] = useState<string[]>([]);
	const [imageFiles, setImageFiles] = useState<any>([]);

	const handleImageUpload = (imageFile: any) => {
		const imageUrl = URL.createObjectURL(imageFile); // Convert file to Blob URL for display
		setImages([...images, imageUrl]);
		setImageFiles([...imageFiles, imageFile]);
		if (onImageUpload) {
			onImageUpload(imageFile); // Pass the actual file to the parent component
		}
	};

	const handleDeleteImage = (imageSrc: string) => {
		setImages(images.filter((src) => src !== imageSrc));
	};

	const getOrdinalNumber = (n: number): string => {
		const s = ["th", "st", "nd", "rd"];
		const v = n % 100;
		return n + (s[(v - 20) % 10] || s[v] || s[0]);
	};

	return (
		<div className="grid gap-6">
			{(!singleUpload || (singleUpload && images.length === 0)) && (
				<ImageUploadButton onChange={handleImageUpload} />
			)}
			{slider ? (
				images.map((src, idx) => (
					<Image
						key={idx}
						src={src}
						slider={slider}
						onDelete={() => handleDeleteImage(src)}
					/>
				))
			) : (
				<div className="grid gap-2 grid-cols-maxTwo">
					{images.map((src, idx) => {
						const imageLabel = `${getOrdinalNumber(idx + 1)} ${label}`;
						return (
							<div key={idx} className="grid gap-2">
								<P size="p3" type="medium">
									{imageLabel}
								</P>
								<Image src={src} onDelete={() => handleDeleteImage(src)} />
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default ImageUpload;
