import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { ReactComponent as ArrowLeft } from "common/assets/icons/arrow-left.svg";
import { Button } from "common/Button/Button";
import { routeConstants } from "navigation";
import { serviceConfig } from "configs";
import ManagePanel from "common/ManagePanel/ManagePanel";
import StyleManagerItem from "common/StyleManagerItem/StyleManagerItem";
import { Input } from "common/Input/Input";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { toast } from "react-toastify";
import ToggleSwitch from "common/ToggleSwitch/ToggleSwitch";
import ImageUpload from "common/Image/ImageUpload";

type Category = {
	_id: number;
	name: string;
};

const MockupedProductsPage: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const mockupData = location.state?.mockups;
	console.log("MOCKUP DATA", mockupData);
	const [selectedProduct, setSelectedProduct] = useState<any>(null);
	const [isManagePanelOpen, setIsManagePanelOpen] = useState(false);
	const [formValues, setFormValues] = useState<any>({
		tag: "",
		title: "",
	});
	console.log("SELECTED PROFUCT", selectedProduct);

	const [isManualImageUploadEnabled, setIsManualImageUploadEnabled] =
		useState(false);
	const [uploadedImageSrc, setUploadedImageSrc] = useState<any>();
	const [selectedColors, setSelectedColors] = useState<any[]>([]);
	const [sizeOptions, setSizeOptions] = useState<any[]>([
		{ selectedSize: null, price: "", sale_price: "" },
	]);
	const [categoryData, setCategoryData] = useState<Category[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
	const [isMockupImageEnabled, setIsMockupImageEnabled] = useState(true);

	useEffect(() => {
		// Fetch categories
		fetch(`${serviceConfig.apiUrl}/shop-category`)
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setCategoryData(data.data);
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error fetching categories:", error));
	}, []);

	const handleToggleManualUpload = (isActive: boolean) => {
		setIsManualImageUploadEnabled(isActive);
	};

	const handleColorSelect = (color: any) => {
		const isSelected = selectedColors.some(
			(selectedColor) => selectedColor.color === color.color
		);
		if (isSelected) {
			setSelectedColors(
				selectedColors.filter(
					(selectedColor) => selectedColor.color !== color.color
				)
			);
		} else {
			setSelectedColors([...selectedColors, color]);
		}
	};

	const handleSizePriceChange = (index: number, field: string, value: any) => {
		const newOptions = [...sizeOptions];
		newOptions[index][field] = value;
		setSizeOptions(newOptions);
	};

	const handleSizeOrDimensionSelect = (
		index: number,
		item: any,
		isDimension = false
	) => {
		const isItemAlreadySelected = sizeOptions.some(
			(option) =>
				option.selectedSize && option.selectedSize.value === item.value
		);

		if (isItemAlreadySelected) {
			toast.error(
				"This " +
					(isDimension ? "dimension" : "size") +
					" has already been selected. Please choose a different one."
			);
			return;
		}

		const newOptions = [...sizeOptions];
		const itemDetails = isDimension
			? selectedProduct.product_dimensions.find(
					(d: any) => d.value === item.value
			  )
			: selectedProduct.product_sizes.find((s: any) => s.value === item.value);

		newOptions[index] = {
			...newOptions[index],
			selectedSize: item,
			price: itemDetails ? itemDetails.price.toString() : "",
			sale_price: itemDetails ? itemDetails.sale_price.toString() : "",
		};
		setSizeOptions(newOptions);
	};

	const handleSubmit = async () => {
		const formData = new FormData();

		// Append non-array product details directly
		formData.append(
			"name",
			`${selectedProduct.product_name} "${formValues.tag}"`
		);
		formData.append("thumbnail", mockupData.imageFile); // Assuming this needs to be the URL/path or File object
		formData.append("product_id", selectedProduct.product_id.toString());
		formData.append(
			"product_name",
			`${selectedProduct.product_name} "${formValues.tag}"`
		);
		formData.append("product_price", selectedProduct.product_price.toString());
		formData.append("product_currency", selectedProduct.product_currency);
		formData.append(
			"product_discount",
			selectedProduct.product_discount.toString()
		);

		// For array fields like descriptions, specifications, etc., convert them to JSON string
		formData.append(
			"product_desc",
			JSON.stringify(selectedProduct.product_desc)
		);
		formData.append(
			"product_shipping_returns",
			JSON.stringify(selectedProduct.product_shipping_returns)
		);
		formData.append(
			"product_specification",
			JSON.stringify(selectedProduct.product_specification)
		);

		// Append sizes and dimensions as JSON strings
		const preparedSizes = selectedProduct.product_sizes
			? sizeOptions
					.filter(
						(option) =>
							option.selectedSize &&
							option.price &&
							selectedProduct.product_sizes.some(
								(size: any) => size.value === option.selectedSize.value
							)
					)
					.map((option) => {
						const baseSize = selectedProduct.product_sizes.find(
							(size: any) => size.value === option.selectedSize.value
						);
						return {
							...baseSize,
							price: parseFloat(option.price),
							sale_price: parseFloat(option.sale_price),
						};
					})
			: [];

		const preparedDimensions = selectedProduct.product_dimensions
			? sizeOptions
					.filter(
						(option) =>
							option.selectedSize &&
							option.price &&
							selectedProduct.product_dimensions.some(
								(dimension: any) =>
									dimension.value === option.selectedSize.value
							)
					)
					.map((option) => {
						const baseDimension = selectedProduct.product_dimensions.find(
							(dimension: any) => dimension.value === option.selectedSize.value
						);
						return {
							...baseDimension,
							price: parseFloat(option.price),
							sale_price: parseFloat(option.sale_price),
						};
					})
			: [];

		formData.append("product_sizes", JSON.stringify(preparedSizes));
		formData.append("product_dimensions", JSON.stringify(preparedDimensions));

		// Colors
		formData.append("product_colors", JSON.stringify(selectedColors));

		formData.append("product_image", selectedProduct.product_image);
		formData.append("product_image_2", selectedProduct.product_image_2 || null);
		formData.append("product_image_3", selectedProduct.product_image_3 || null);
		formData.append("product_image_4", selectedProduct.product_image_4 || null);
		formData.append("product_image_5", selectedProduct.product_image_5 || null);

		formData.append("title", formValues.title);

		selectedCategory.forEach((categoryId: any) => {
			formData.append("categories", JSON.stringify(categoryId));
		});

		// Handling uploaded image files
		if (uploadedImageSrc instanceof File && isManualImageUploadEnabled) {
			// This check ensures uploadedImageSrc is a File object
			formData.append("image", uploadedImageSrc, uploadedImageSrc.name);
		}

		formData.append("mockupImageEnabled", JSON.stringify(isMockupImageEnabled));

		// Assuming the rest of the product images are URLs or not meant to be uploaded this way
		// If you have File objects for these as well, append them similarly to 'uploadedImage'

		try {
			const response = await fetch(`${serviceConfig.apiUrl}/save-to-shop`, {
				method: "POST",
				body: formData, // Use FormData here
				// Do not set Content-Type header, as it's automatically set by the FormData object
			});

			if (response.ok) {
				const result = await response.json();
				console.log("Response from server:", result);
				// Handle success (reset form, close manage panel, etc.)
				setIsManagePanelOpen(false);
				setSelectedProduct(null);
				setSelectedColors([]);
				setSizeOptions([{ selectedSize: null, price: "", sale_price: "" }]);
				setFormValues({ tag: "" });
				setSelectedCategory([]);
			} else {
				console.error("Server responded with non-OK status", response.status);
			}
		} catch (error) {
			console.error("Error making API request:", error);
		}
	};

	const handleProductSelect = (product: any) => {
		setSelectedProduct(product);
		setIsManagePanelOpen(true);
		// Initialize form values based on the selected product
	};

	const closeManagePanel = () => {
		setIsManagePanelOpen(false);
		setSelectedProduct(null);
		setSelectedColors([]);
		setSizeOptions([{ selectedSize: null, price: "", sale_price: "" }]);
		setFormValues({ tag: "" }); // Reset other form values as needed
	};

	const handleAddMoreSize = () => {
		setSizeOptions([
			...sizeOptions,
			{ selectedSize: null, price: "", sale_price: "" },
		]);
	};

	const handleCategoryClick = (id: number) => {
		console.log("Clicked category ID:", id);
		if (selectedCategory.includes(id)) {
			setSelectedCategory((prevSizes) =>
				prevSizes.filter((sizeId) => sizeId !== id)
			);
		} else {
			setSelectedCategory((prevSizes) => [...prevSizes, id]);
		}
		console.log(selectedCategory); // Log the state after updating
	};

	return (
		<div className="grid gap-8 p-8">
			<div className="grid grid-cols-maxTwo place-content-between place-items-center">
				<div className="grid gap-1">
					<Htag tag="h2" type="medium">
						Mockup
					</Htag>
					<P size="p2" type="regular" className="text-passiveTextColor">
						Track, manage and forecast your designs and orders.
					</P>
				</div>
				<Button
					appearance="black"
					icon={<ArrowLeft />}
					onClick={() => navigate(routeConstants.shopMockup.route)}
				>
					Back to design upload
				</Button>
			</div>
			<div className="grid grid-cols-1 gap-4 md:grid-cols-maxTwo lg:grid-cols-maxThree">
				{mockupData?.mockups.map((product: any) => (
					<div
						key={product.product_id}
						onClick={() => handleProductSelect(product)}
						className="grid gap-3"
					>
						<div className="w-[256px] h-[256px] cursor-pointer">
							<img
								src={`${serviceConfig.apiUrl}${
									product.product_colors.length > 0
										? product.product_colors[0].image
										: product.product_image
								}`}
								alt={product.product_name}
								className="object-cover w-full h-full rounded-lg shadow-lg"
							/>
						</div>
						<P size="p1" type="semibold" className="">
							{product.product_name}
						</P>
					</div>
				))}
			</div>
			{selectedProduct && (
				<ManagePanel
					isOpen={isManagePanelOpen}
					onClose={closeManagePanel}
					label={selectedProduct.product_name}
					onSave={handleSubmit}
					onDiscard={closeManagePanel}
				>
					<div className="grid gap-6">
						{selectedProduct.product_colors &&
						selectedProduct.product_colors.length > 0 ? (
							selectedColors.length > 0 ? (
								<div className="flex flex-wrap gap-2">
									{selectedColors.map((color, index) => (
										<img
											key={index}
											src={`${serviceConfig.apiUrl}${color.image}`}
											alt={color.color}
											className="object-cover w-[126px] h-[126px] rounded-lg shadow-lg"
										/>
									))}
								</div>
							) : null
						) : (
							<img
								src={`${serviceConfig.apiUrl}${selectedProduct.product_image}`}
								alt={selectedProduct.product_name}
								className="object-cover w-[126px] h-[126px] rounded-lg shadow-lg"
							/>
						)}

						{selectedProduct?.product_name === "Canvas" && (
							<ToggleSwitch
								isActive={isMockupImageEnabled}
								onToggle={() => setIsMockupImageEnabled(!isMockupImageEnabled)}
								label="Mockup Image"
							/>
						)}

						<ToggleSwitch
							isActive={isManualImageUploadEnabled}
							onToggle={handleToggleManualUpload}
							label="Add manually image"
						/>

						{isManualImageUploadEnabled && (
							<ImageUpload
								singleUpload={true}
								onImageUpload={setUploadedImageSrc}
							/>
						)}

						<Input
							withlabel={true}
							appearance="default"
							type="text"
							name="tag"
							label="Tag"
							placeholder="Enter tag..."
							value={formValues.tag}
							onChange={(e) =>
								setFormValues((prev: any) => ({
									...prev,
									tag: e.target.value,
								}))
							}
						/>
						<Input
							withlabel={true}
							appearance="default"
							type="text"
							name="title"
							label="Title"
							placeholder="Enter title..."
							value={formValues.title}
							onChange={(e) =>
								setFormValues((prev: any) => ({
									...prev,
									title: e.target.value,
								}))
							}
						/>

						<div className="flex flex-wrap gap-2">
							{categoryData.map((category) => (
								<StyleManagerItem
									key={category._id}
									label={category.name}
									paddingY="py-2"
									onClick={() => handleCategoryClick(category._id)}
									className={`${
										selectedCategory.includes(category._id)
											? "bg-black text-white"
											: "bg-white text-black"
									} cursor-pointer`}
								/>
							))}
						</div>

						{selectedProduct.product_colors &&
							selectedProduct.product_colors.length > 0 && (
								<div className="grid gap-2">
									<P size="p3" type="medium">
										Select Color
									</P>
									<div className="flex flex-wrap gap-2">
										{selectedProduct.product_colors.map((color: any) => (
											<StyleManagerItem
												key={color.color}
												label={color.color}
												onClick={() => handleColorSelect(color)}
												className={
													selectedColors.some(
														(selectedColor) =>
															selectedColor.color === color.color
													)
														? "bg-mainColor text-white"
														: ""
												}
											/>
										))}
									</div>
								</div>
							)}

						{/* Size/Dimension Selection */}
						<div className="grid gap-6">
							{/* Existing sizes */}

							{sizeOptions.map((option, index) => (
								<div key={index} className="grid gap-3">
									{selectedProduct.product_sizes &&
										selectedProduct.product_sizes.length > 0 && (
											<div className="grid gap-2">
												<P size="p3" type="medium">
													Select Size
												</P>
												<div className="flex flex-wrap gap-2">
													{selectedProduct.product_sizes.map((size: any) => (
														<StyleManagerItem
															key={size.value}
															label={size.value}
															onClick={() =>
																handleSizeOrDimensionSelect(index, size)
															}
															className={
																option.selectedSize === size
																	? "bg-mainColor text-white"
																	: ""
															}
														/>
													))}
												</div>
											</div>
										)}

									{/* Dimension Selection */}
									{selectedProduct.product_dimensions &&
										selectedProduct.product_dimensions.length > 0 && (
											<div className="grid gap-2">
												<P size="p3" type="medium">
													Select Dimensions
												</P>
												<div className="flex flex-wrap gap-2">
													{selectedProduct.product_dimensions.map(
														(dimension: any) => (
															<StyleManagerItem
																key={dimension.value}
																label={dimension.value}
																onClick={() =>
																	handleSizeOrDimensionSelect(
																		index,
																		dimension,
																		true
																	)
																}
																className={
																	option.selectedSize === dimension
																		? "bg-mainColor text-white"
																		: ""
																}
															/>
														)
													)}
												</div>
											</div>
										)}

									<Input
										price={true}
										appearance="default"
										type="text"
										name="price"
										label="Price"
										placeholder="Enter price for this size..."
										value={option.price}
										onChange={(e) =>
											handleSizePriceChange(index, "price", e.target.value)
										}
									/>

									<Input
										price={true}
										appearance="default"
										type="text"
										name="sale_price"
										label="Sale price"
										placeholder="Enter sale price for this size..."
										value={option.sale_price}
										onChange={(e) =>
											handleSizePriceChange(index, "sale_price", e.target.value)
										}
									/>
								</div>
							))}
						</div>

						<div
							onClick={handleAddMoreSize}
							className="grid gap-1 cursor-pointer grid-cols-maxTwo place-items-center place-self-end"
						>
							<PlusIcon className="stroke-mainColor" />
							<P size="p2" type="medium" className="cursor-pointer">
								Add more size
							</P>
						</div>
					</div>
				</ManagePanel>
			)}
		</div>
	);
};

export default MockupedProductsPage;
