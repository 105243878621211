import React, { useState } from "react";
import EmptyState from "common/EmptyState/EmptyState";
import ManagePanel from "common/ManagePanel/ManagePanel";
import StyleManagerItem from "common/StyleManagerItem/StyleManagerItem";
import { P } from "common/P/P";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { Input } from "common/Input/Input";

interface StyleManagerCardProps {
	styles: string[];
	onAddStyle?: (style: string) => void; // A function to handle addition of new styles
	onSave: (newStyle: string) => Promise<void>;
	label: string;
	paddingY?: string;
	managePanelLabel: string;
	managePanelTitle: string;
	managePanelInputLabel: string;
}

const StyleManagerCard: React.FC<StyleManagerCardProps> = ({
	styles,
	onAddStyle,
	onSave,
	label,
	paddingY,
	managePanelLabel,
	managePanelTitle,
	managePanelInputLabel,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [newStyles, setNewStyles] = useState(Array(10).fill(""));

	const handleInputChange = (index: number, value: string) => {
		const updatedStyles = [...newStyles];
		updatedStyles[index] = value;
		setNewStyles(updatedStyles);
	};

	const handleAddAndSave = async () => {
		// Loop through the newStyles and save each one, then add to the styles list
		for (const style of newStyles) {
			if (style) {
				try {
					await onSave(style); // API call
					// onAddStyle(style); // Update local state after successful API call
				} catch (error) {
					console.error("Error saving the style:", error);
				}
			}
		}
		setNewStyles(Array(10).fill("")); // Reset the input fields after saving
		setIsOpen(false);
	};

	return (
		<div>
			<div className="grid max-w-[629px] gap-8 p-6 border rounded-lg shadow-xs border-strokeColor">
				<div className="grid grid-cols-maxTwo place-content-between place-items-center">
					<P size="p1" type="medium">
						{label}
					</P>

					<button
						className="grid gap-1 text-textColor grid-cols-maxTwo place-items-center"
						onClick={() => setIsOpen(true)}
					>
						<PlusIcon className="stroke-mainColor" />
						<P size="p2" type="medium" className="cursor-pointer">
							Add
						</P>
					</button>
				</div>
				<div
					className={`${
						styles.length > 0 ? "flex flex-wrap gap-3" : "place-content-stretch"
					}`}
				>
					{styles.length > 0 ? (
						styles.map((style, idx) => (
							<StyleManagerItem key={idx} label={style} paddingY={paddingY} />
						))
					) : (
						<EmptyState
							title="No categories yet"
							className="place-content-stretch"
						/>
					)}
				</div>
			</div>

			{isOpen && (
				<ManagePanel
					onClose={() => setIsOpen(false)}
					onSave={handleAddAndSave}
					isOpen={isOpen}
					label={managePanelLabel}
					onDiscard={() => setIsOpen(false)}
					width="w-[515px]"
				>
					<div className="grid gap-6">
						<P size="p2" type="regular">
							{managePanelTitle}
						</P>
						<div className="grid gap-4 grid-cols-maxTwo">
							{Array.from({ length: 10 }).map((_, index) => (
								<Input
									key={index}
									withlabel={true}
									appearance="default"
									type="text"
									name={`style-${index}`}
									label={`${managePanelInputLabel} №${index + 1}`}
									value={newStyles[index]}
									onChange={(e) => handleInputChange(index, e.target.value)}
								/>
							))}
						</div>
					</div>
				</ManagePanel>
			)}
		</div>
	);
};

export default StyleManagerCard;
