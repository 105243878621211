import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import StyleManagerCard from "components/StyleManagerCard/StyleManagerCard";
import { serviceConfig } from "configs";
import { useEffect, useState } from "react";

const mockCategories = [
	"Electronics",
	"Clothing",
	"Home & Kitchen",
	"Books",
	"Toys",
];

const CategoriesPage: React.FC = () => {
	const [categories, setCategories] = useState<string[]>([]);

	useEffect(() => {
		fetch(`${serviceConfig.apiUrl}/category`)
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setCategories(data.data.map((category: any) => category.name));
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error fetching categories:", error));
	}, []);

	const saveNewCategory = async (newCategory: string) => {
		return fetch(`${serviceConfig.apiUrl}/category`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ name: newCategory }),
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setCategories((prevCategories) => [
						...prevCategories,
						data.data.name,
					]);
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error saving category:", error));
	};

	return (
		<div className="p-8">
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					Categories
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					Track, manage and forecast your categories and products.
				</P>
			</div>
			<div className="grid grid-cols-1 gap-6 mt-8">
				<StyleManagerCard
					styles={categories}
					onSave={saveNewCategory}
					label="Categories"
					managePanelLabel="Add Category"
					managePanelTitle="You can add 10 categories at a time"
					managePanelInputLabel="Category"
				/>
			</div>
		</div>
	);
};

export default CategoriesPage;
