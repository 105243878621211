import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import Pagination from "components/Pagination/Pagination";
import UserTable from "components/UserTable/UserTable";
import { serviceConfig } from "configs";
import ManagePanel from "common/ManagePanel/ManagePanel";
import Modal from "common/Modal/Modal";
import { Input } from "common/Input/Input";
import { SearchInput } from "common/Search/Search";

const data = [
	{
		id: 1,
		user: "Mekan Hojaberdiyew",
		phone_number: "+99362665444",
		email: "johnjoseph@gmail.com",
		address: "4517 Washington Ave. Manchester, Kentucky 39495",
		orders: [
			{
				id: 1,
				products: [
					{
						id: 1,
						name: "T-shirt",
					},
					{
						id: 2,
						name: "Canvas",
					},
				],
				address: "4517 Washington Ave. Manchester, Kentucky 39495",
				status: "Delivered",
				price: "500 $",
			},
		],
	},
	{
		id: 2,
		user: "123 ss",
		phone_number: "+123213213",
		email: "johnjoseph@gmail.com",
		address: "4517 Washington Ave. Manchester, Kentucky 39495",
		orders: [
			{
				id: 1,
				products: [
					{
						id: 1,
						name: "T-shirt",
					},
					{
						id: 2,
						name: "Canvas",
					},
				],
				address: "4517 Washington Ave. Manchester, Kentucky 39495",
				status: "Delivered",
				price: "500 $",
			},
		],
	},
];

const UserManagementPage: React.FC = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [editingUser, setEditingUser] = useState<any>(null);
	const [deletingUser, setDeletingUser] = useState<any>(null);
	const [viewUser, setViewUser] = useState(null);
	const [waitlists, setWaitlists] = useState([]);
	const [loading, setLoading] = useState(true);
	const [totalPages, setTotalPages] = useState(0);
	const itemsPerPage = 8;
	const [usersData, setUsersData] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");

	const [formValues, setFormValues] = useState({
		user: "",
		phone_number: "",
		email: "",
		address: "",
	});

	useEffect(() => {
		if (editingUser) {
			setFormValues({
				user: editingUser.user || "",
				phone_number: editingUser.phone_number || "",
				email: editingUser.email || "",
				address: editingUser.address || "",
			});
		}
	}, [editingUser]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormValues((prev) => ({ ...prev, [name]: value }));
	};
	const fetchUsers = async () => {
		try {
			const response = await fetch(`${serviceConfig.apiUrl}/users`);
			const result = await response.json();

			// Transform data structure to fit frontend components
			const transformedData = result.data.map((user: any) => ({
				id: user._id,
				user: `${user.RpAccName}`,
				phone_number:
					user.addresses &&
					user.addresses.length > 0 &&
					user.addresses[0].phone_number
						? user.addresses[0].phone_number
						: "No number",
				email: user.RpAccEMail,
				address: user.RpAccAddress,
				orders: user.orders.map((order: any) => ({
					id: order._id,
					products: order.items.map((item: any) => ({
						id: item.product_id,
						name: item.product_name,
					})),
					address: order.address1,
					status: order.status,
					price: `$${order.total}`,
				})),
			}));

			setUsersData(transformedData);
			setLoading(false);
		} catch (error) {
			console.error("Failed to fetch users", error);
			setLoading(false);
		}
	};

	const updateUser = async () => {
		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/users/${editingUser.id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(formValues),
				}
			);
			const responseData = await response.json();
			console.log("Update response:", responseData);

			if (response.ok) {
				alert("User updated successfully!");
				setEditingUser(null);
				// Refresh the users data
				fetchUsers();
			} else {
				alert("Failed to update user.");
			}
		} catch (error) {
			console.error("Failed to update user", error);
		}
	};

	const deleteUser = async () => {
		if (!deletingUser) return;
		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/users/${deletingUser.id}`,
				{
					method: "DELETE",
				}
			);

			if (response.ok) {
				alert("User deleted successfully!");
				setDeletingUser(null);
				// Refresh the users data
				fetchUsers();
			} else {
				alert("Failed to delete user.");
			}
		} catch (error) {
			console.error("Failed to delete user", error);
		}
	};

	useEffect(() => {
		fetchUsers();
	}, []);

	const currentWaitlists = waitlists.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	const handleSearch = async () => {
		if (searchQuery.length === 0) {
			fetchUsers(); // Fetch all users if the search query is empty
			return;
		}

		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/users/search?q=${searchQuery}`
			);
			const result = await response.json();

			// Transform data structure to fit frontend components
			const transformedData = result.data.map((user: any) => ({
				id: user._id,
				user: `${user.RpAccName}`,
				phone_number:
					user.addresses &&
					user.addresses.length > 0 &&
					user.addresses[0].phone_number
						? user.addresses[0].phone_number
						: "No number",
				email: user.RpAccEMail,
				address: user.RpAccAddress,
				orders: user.orders.map((order: any) => ({
					id: order._id,
					products: order.items.map((item: any) => ({
						id: item.product_id,
						name: item.product_name,
					})),
					address: order.address1,
					status: order.status,
					price: `$${order.total}`,
				})),
			}));

			setUsersData(transformedData);
		} catch (error) {
			console.error("Failed to fetch search results", error);
		}
	};

	return (
		<div className="p-8">
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					User Management
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					Track, manage and forecast your customers and orders.
				</P>
			</div>
			<div className="grid gap-6">
				{loading ? (
					<p>Loading...</p>
				) : (
					<>
						<div className="grid gap-4">
							<div className="grid items-center grid-cols-2 px-5 py-3 mt-6 border rounded-md border-strokeColor">
								<Htag tag="h4" type="semibold">
									Users
								</Htag>
								<SearchInput
									placeholder="Search"
									onChange={(e) => setSearchQuery(e.target.value)}
									handleSearch={handleSearch}
								/>
							</div>
							<UserTable
								data={usersData}
								onEdit={(item) => setEditingUser(item)}
								onDelete={(item) => setDeletingUser(item)}
								onView={(item) => setViewUser(item)}
							/>
						</div>
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={setCurrentPage}
						/>
					</>
				)}
			</div>
			{editingUser && (
				<ManagePanel
					isOpen={!!editingUser}
					onClose={() => setEditingUser(null)}
					label="Edit User"
					onSave={updateUser} // Updated the onSave logic here
					onDiscard={() => setEditingUser(null)}
				>
					<div className="grid gap-4">
						<Input
							label="User Name"
							withlabel={true}
							value={formValues.user}
							name="user"
							placeholder="Enter user name"
							onChange={handleInputChange}
							appearance="default"
							type="text"
						/>
						<Input
							label="Phone Number"
							withlabel={true}
							value={formValues.phone_number}
							name="phone_number"
							placeholder="Enter phone number"
							onChange={handleInputChange}
							appearance="default"
							type="text"
						/>
						<Input
							label="Email"
							withlabel={true}
							value={formValues.email}
							name="email"
							placeholder="Enter email"
							onChange={handleInputChange}
							appearance="default"
							type="text"
						/>
						<Input
							label="Address"
							withlabel={true}
							value={formValues.address}
							name="address"
							placeholder="Enter address"
							onChange={handleInputChange}
							appearance="default"
							type="text"
						/>
					</div>
				</ManagePanel>
			)}
			{deletingUser && (
				<Modal
					isOpen={!!deletingUser}
					onClose={() => setDeletingUser(null)}
					title="Delete User"
					desc={`Are you sure you want to delete this user? This action cannot be undone.`}
					onConfirm={deleteUser} // Updated the onConfirm logic here
					onCancel={() => setDeletingUser(null)}
				/>
			)}
		</div>
	);
};

export default UserManagementPage;
