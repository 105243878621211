import React from "react";
import { SearchInputProps } from "./Search.props";
import "./Search.css";
import { ReactComponent as SearchIcon } from "common/assets/icons/search.svg";

export const SearchInput: React.FC<SearchInputProps> = ({
	value,
	placeholder = "Search...",
	onChange,
	className,
	handleSearch,
}) => {
	return (
		<div className={`relative bg-white shadow-xs ${className}`}>
			<span className="absolute inset-y-0 left-0 grid pl-4 place-items-center">
				<SearchIcon className="stroke-passiveTextColor" />
			</span>
			<input
				type="text"
				value={value}
				onChange={onChange}
				className={`input pl-12 w-full h-full py-3 border border-strokeColor rounded-lg hover:border-mainColor focus:border-mainColor transition-all duration-300`}
				placeholder={placeholder}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						handleSearch();
					}
				}}
			/>
		</div>
	);
};
