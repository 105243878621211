import React from "react";
import { HtagProps } from "./Htag.props";

export const Htag = ({
	tag,
	type,
	children,
	className,
}: HtagProps): JSX.Element => {
	switch (tag) {
		case "h1":
			return (
				<h1
					className={`text-4xl tracking-[-2%] cursor-default ${
						type === "regular"
							? "font-normal"
							: type === "medium"
							? "font-medium"
							: type === "semibold"
							? "font-semibold"
							: type === "bold"
							? "font-bold"
							: ""
					} ${className}`}
				>
					{children}
				</h1>
			);
		case "h2":
			return (
				<h2
					className={`text-3xl cursor-default ${
						type === "regular"
							? "font-normal"
							: type === "medium"
							? "font-medium"
							: type === "semibold"
							? "font-semibold"
							: type === "bold"
							? "font-bold"
							: ""
					} ${className}`}
				>
					{children}
				</h2>
			);
		case "h3":
			return (
				<h3
					className={`text-2xl cursor-default ${
						type === "regular"
							? "font-normal"
							: type === "medium"
							? "font-medium"
							: type === "semibold"
							? "font-semibold"
							: type === "bold"
							? "font-bold"
							: ""
					} ${className}`}
				>
					{children}
				</h3>
			);
		case "h4":
			return (
				<h4
					className={`text-xl cursor-default ${
						type === "regular"
							? "font-normal"
							: type === "medium"
							? "font-medium"
							: type === "semibold"
							? "font-semibold"
							: type === "bold"
							? "font-bold"
							: ""
					} ${className}`}
				>
					{children}
				</h4>
			);
		default:
			return <></>;
	}
};
