import { Navigate } from "react-router-dom";
import { routeConstants } from "navigation";

interface PrivateRouteProps {
	element: React.ReactElement;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
	return localStorage.getItem("token") ? (
		element
	) : (
		<Navigate to={routeConstants.login.route} replace />
	);
};
