import { Component, ReactNode } from "react";
import { ErrorIndicator } from "modules/errors/ErrorIndicator";

interface Props {
	children?: ReactNode;
}

export default class ErrorBoundary extends Component<Props> {
	state = {
		hasError: false,
	};

	componentDidCatch() {
		this.setState({
			hasError: true,
		});
	}

	render() {
		if (this.state.hasError) {
			return <ErrorIndicator />;
		}
		return this.props.children;
	}
}
