import { route_prefix } from "configs";

export const routeConstants = {
	root: {
		route: `${route_prefix}/`,
		name: "Home",
	},
	dashboard: {
		route: `${route_prefix}/dashboard`,
		name: "Dashboard",
	},
	userManagement: {
		route: `${route_prefix}/user-management`,
		name: "User Management",
	},
	userManagementDetails: {
		route: `${route_prefix}/user-management-details`,
		name: "User Management Details",
	},
	orderManagement: {
		route: `${route_prefix}/order-management`,
		name: "Order Management",
	},
	subscribe: {
		route: `${route_prefix}/subscribe`,
		name: "Subscribe",
	},
	designDashboard: {
		route: `${route_prefix}/design-dashboard`,
		name: "Design Dashboard",
	},
	designList: {
		route: `${route_prefix}/design-list`,
		name: "Design List",
	},
	categories: {
		route: `${route_prefix}/categories`,
		name: "Categories",
	},
	blogs: {
		route: `${route_prefix}/blogs`,
		name: "Blogs",
	},
	faq: {
		route: `${route_prefix}/faq`,
		name: "FAQ",
	},
	shopList: {
		route: `${route_prefix}/shop-list`,
		name: "Shop List",
	},
	shopMockup: {
		route: `${route_prefix}/shop-mockup`,
		name: "Shop Mockup",
	},
	shopCategories: {
		route: `${route_prefix}/shop-categories`,
		name: "Shop Categories",
	},
	mockupedProducts: {
		route: `${route_prefix}/mockuped-products`,
		name: "Mockuped Products",
	},
	login: {
		route: `${route_prefix}/login`,
		name: "Login",
	},
	logout: {
		route: `${route_prefix}/logout`,
		name: "Logout",
	},
};
