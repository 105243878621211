import React from "react";
import { StyleManagerItemProps } from "./StyleManagerItem.props";

const StyleManagerItem: React.FC<StyleManagerItemProps> = ({
	label,
	paddingY = "py-1",
	onClick,
	className = "bg-whiteColor",
}) => {
	return (
		<div
			className={`border rounded-md border-strokeColor ${paddingY} px-4 cursor-pointer ${className}`}
			onClick={onClick}
		>
			{label}
		</div>
	);
};

export default StyleManagerItem;
