import OrderStatus from "common/OrderStatus/OrderStatus";
import React from "react";

interface OrderHistoryTableProps {
	data: any;
}

const OrderHistoryTable: React.FC<OrderHistoryTableProps> = ({ data }) => {
	console.log("GETTING", data);
	return (
		<table className="w-full border rounded-lg border-strokeColor">
			<thead className="bg-white border border-strokeColor">
				<tr>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Products
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Address
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Status
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Price
					</th>
				</tr>
			</thead>
			<tbody className="text-left">
				{data.map((order: any, idx: number) => (
					<tr
						key={order.id}
						className={
							idx % 2 === 0 ? "bg-white" : "bg-[#f9f9f9] cursor-pointer"
						}
					>
						<td className="px-5 py-4 font-medium text-textColor">
							{order.products.map((product: any) => product.name).join(", ")}
						</td>
						<td className="px-5 py-4 font-medium text-textColor">
							{order.address}
						</td>
						<td className="px-5 py-4 font-medium text-textColor">
							<OrderStatus status={order.status} />
						</td>
						<td className="px-5 py-4 font-medium text-textColor">
							{order.price}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default OrderHistoryTable;
