import React, { useState } from "react";

import { ReactComponent as Logo } from "common/assets/icons/logo.svg";
import { ReactComponent as LogoutIcon } from "common/assets/icons/log-out.svg";
import { Link } from "react-router-dom";
import { routeConstants } from "navigation";

import { ReactComponent as DashboardIcon } from "common/assets/icons/stat-up.svg";
import { ReactComponent as UserIcon } from "common/assets/icons/verified-user.svg";
import { ReactComponent as OrdersIcon } from "common/assets/icons/cart-alt.svg";
import { ReactComponent as DesignIcon } from "common/assets/icons/media-image.svg";
import { ReactComponent as PointIcon } from "common/assets/icons/point.svg";
import { ReactComponent as ContentIcon } from "common/assets/icons/cable-tag.svg";
import { ReactComponent as ShopIcon } from "common/assets/icons/t-shirt.svg";
import { ReactComponent as BellRingIcon } from "common/assets/icons/BellRinging.svg";

import { ReactComponent as ArrowUpIcon } from "common/assets/icons/nav-arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "common/assets/icons/nav-arrow-down.svg";

const contentData = [
	{
		id: 1,
		name: "Dashboard",
		link: routeConstants.dashboard.route,
		icon: (
			<DashboardIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
		),
	},
	{
		id: 2,
		name: "User Management",
		link: routeConstants.userManagement.route,
		icon: (
			<UserIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
		),
	},
	{
		id: 3,
		name: "Order Management",
		link: routeConstants.orderManagement.route,
		icon: (
			<OrdersIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
		),
	},

	{
		id: 4,
		name: "Design Management",
		link: null,
		icon: (
			<DesignIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
		),
		children: [
			{
				id: 1,
				name: "Dashboard",
				link: routeConstants.designDashboard.route,
				icon: (
					<PointIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
				),
			},
			{
				id: 2,
				name: "Design list",
				link: routeConstants.designList.route,
				icon: (
					<PointIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
				),
			},
			{
				id: 3,
				name: "Categories",
				link: routeConstants.categories.route,
				icon: (
					<PointIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
				),
			},
		],
	},
	{
		id: 5,
		name: "Content Management",
		link: null,
		icon: (
			<ContentIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
		),
		children: [
			{
				id: 1,
				name: "Blog/Articles",
				link: routeConstants.blogs.route,
				icon: (
					<PointIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
				),
			},
			{
				id: 2,
				name: "FAQ",
				link: routeConstants.faq.route,
				icon: (
					<PointIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
				),
			},
		],
	},
	{
		id: 6,
		name: "Shop Management",
		link: null,
		icon: (
			<ShopIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
		),
		children: [
			{
				id: 1,
				name: "Shop list",
				link: routeConstants.shopList.route,
				icon: (
					<PointIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
				),
			},
			{
				id: 2,
				name: "Mockup",
				link: routeConstants.shopMockup.route,
				icon: (
					<PointIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
				),
			},
			{
				id: 2,
				name: "Categories",
				link: routeConstants.shopCategories.route,
				icon: (
					<PointIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
				),
			},
		],
	},
	{
		id: 6,
		name: "Subscribers",
		link: routeConstants.subscribe.route,
		icon: (
			<BellRingIcon className="fill-passiveTextColor group-hover:fill-textColor" />
		),
	},
];

const Sidebar: React.FC = (): JSX.Element => {
	const [activeRoute, setActiveRoute] = useState<string>("");
	const [dropdownOpen, setDropdownOpen] = useState<string[]>([]);

	const handleLogout = () => {
		localStorage.removeItem("token");
	};

	const toggleDropdown = (id: string) => {
		if (dropdownOpen.includes(id)) {
			// If the ID is already in the array, remove it
			setDropdownOpen((prevState) => prevState.filter((item) => item !== id));
		} else {
			// Otherwise, add the ID to the array
			setDropdownOpen((prevState) => [...prevState, id]);
		}
	};

	const username = localStorage.getItem("user");
	return (
		<div className="fixed top-0 left-0 h-screen w-[295px] bg-whiteColor border border-gray-200 overflow-hidden">
			<div className="px-6 py-8 border-b border-gray-200 cursor-default">
				<Logo />
			</div>
			<div className="px-4 py-4 overflow-y-auto max-h-[calc(100vh-200px)]">
				{contentData.map((item: any, idx: number) => (
					<div key={idx}>
						<div
							className={`grid gap-3 px-3 py-2 transition-all rounded-md cursor-pointer  group duration-400 ${
								item.children ? "grid-cols-maxMaxFr" : "grid-cols-maxFr"
							} ${
								activeRoute === item.link ? "bg-hoverColor" : "bg-whiteColor"
							} hover:bg-gray-100`}
						>
							{item.icon &&
								React.cloneElement(item.icon, {
									className: `${
										activeRoute === item.link
											? "stroke-textColor"
											: "stroke-passiveTextColor group-hover:stroke-textColor"
									}`,
								})}
							{item.link ? (
								<Link to={item.link} onClick={() => setActiveRoute(item.link)}>
									<p
										className={`text-base ${
											activeRoute === item.link
												? "text-textColor"
												: "text-passiveTextColor"
										} group-hover:text-textColor`}
									>
										{item.name}
									</p>
								</Link>
							) : (
								<p
									className={`text-base ${
										activeRoute === item.link
											? "text-textColor"
											: "text-passiveTextColor"
									} group-hover:text-textColor`}
									onClick={() => {
										if (item.children) {
											toggleDropdown(item.id.toString());
										}
									}}
								>
									{item.name}
								</p>
							)}
							{item.children && (
								<div
									onClick={() => toggleDropdown(item.id.toString())}
									className="place-self-end"
								>
									{dropdownOpen.includes(item.id.toString()) ? (
										<ArrowUpIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
									) : (
										<ArrowDownIcon className="stroke-passiveTextColor group-hover:stroke-textColor" />
									)}
								</div>
							)}
						</div>
						{item.children && dropdownOpen.includes(item.id.toString()) && (
							<div className="pl-4">
								{item.children.map((childItem: any, childIdx: number) => (
									<Link
										to={childItem.link}
										key={childIdx}
										onClick={() => setActiveRoute(childItem.link)}
									>
										<div
											className={`grid gap-3 px-3 py-2 transition-all rounded-md cursor-pointer grid-cols-maxFr group duration-400 ${
												activeRoute === childItem.link
													? "bg-hoverColor"
													: "bg-whiteColor"
											} hover:bg-gray-100`}
										>
											{childItem.icon &&
												React.cloneElement(childItem.icon, {
													className: `${
														activeRoute === childItem.link
															? "stroke-textColor"
															: "stroke-passiveTextColor group-hover:stroke-textColor"
													}`,
												})}
											<p
												className={`text-base ${
													activeRoute === childItem.link
														? "text-textColor"
														: "text-passiveTextColor"
												} group-hover:text-textColor`}
											>
												{childItem.name}
											</p>
										</div>
									</Link>
								))}
							</div>
						)}
					</div>
				))}
			</div>

			<div className="absolute bottom-0 w-[280px]">
				<div className="grid items-center gap-3 px-4 py-6 border-t border-gray-200 grid-cols-maxTwo place-content-between">
					<p className="font-semibold cursor-default text-passiveTextColor">
						{username}
					</p>
					<Link to={routeConstants.logout.route} onClick={handleLogout}>
						<LogoutIcon className="cursor-pointer" />
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
