import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Input } from "common/Input/Input";
import Modal from "common/Modal/Modal";
import StyleManagerItem from "common/StyleManagerItem/StyleManagerItem";
import { SearchInput } from "common/Search/Search";
import { serviceConfig } from "configs";
import ShopCard from "components/ShopCard/ShopCard";
import formatDate from "utils/formatDate";

type Category = {
	_id: number;
	name: string;
};

const ShopListPage: React.FC = () => {
	const [shopStores, setShopStores] = useState<any[]>([]);

	const [searchQuery, setSearchQuery] = useState<string>("");
	const [selectedShopStore, setSelectedShopStore] = useState<any>(null);
	const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deletingShopStoreId, setDeletingShopStoreId] = useState<number | null>(
		null
	);
	const tagMatch = selectedShopStore?.product?.product_name.match(/"([^"]+)"/);
	const productTag = tagMatch ? tagMatch[1] : ""; // Get the captured group
	console.log(selectedShopStore);
	const [editFormValues, setEditFormValues] = useState<any>({
		tag: selectedShopStore ? productTag : "",
		title: selectedShopStore ? selectedShopStore.title : "",
		selectedColors: [], // assuming an array of indices representing selected colors
		sizeOptions: selectedShopStore
			? selectedShopStore.product.product_sizes.map(() => ({
					selected: false,
					price: "",
					sale_price: "",
			  }))
			: [],
		name: selectedShopStore ? selectedShopStore.product.product_name : "",
		dimensionOptions: selectedShopStore
			? selectedShopStore.product.product_dimensions.map(() => ({
					selected: false,
					price: "",
					sale_price: "",
			  }))
			: [],
		createdAt: selectedShopStore ? selectedShopStore.createdAt : "",
		updatedAt: selectedShopStore ? selectedShopStore.updatedAt : "",
	});
	const [categoryData, setCategoryData] = useState<Category[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<number[]>([]);

	console.log(editFormValues);

	useEffect(() => {
		// Fetch categories
		fetch(`${serviceConfig.apiUrl}/shop-category`)
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setCategoryData(data.data);
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error fetching categories:", error));
	}, []);

	const fetchShopStores = async () => {
		try {
			const response = await fetch(`${serviceConfig.apiUrl}/all-shop-stores`);
			const result = await response.json();
			if (result.success) {
				setShopStores(result.data);
			} else {
				console.error("Error fetching shop stores:", result.message);
			}
		} catch (error) {
			console.error("Failed to fetch shop stores", error);
		}
	};

	// Use useEffect to fetch data on component mount
	useEffect(() => {
		fetchShopStores();
	}, []);

	const handleUpdateShopStore = async (id: any, updateData: any) => {
		try {
			const response = await fetch(`${serviceConfig.apiUrl}/shop-store/${id}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(updateData),
			});
			const result = await response.json();

			if (result.success) {
				// Update the UI after successful update
				setShopStores(
					shopStores.map((store) => (store._id === id ? result.data : store))
				);
			} else {
				console.error("Failed to update shop store:", result.message);
			}
		} catch (error) {
			console.error("Error updating shop store:", error);
		}
	};

	const handleSearch = async () => {
		if (searchQuery.length === 0) {
			// If the search input is empty, fetch all designs
			fetch(`${serviceConfig.apiUrl}/all-shop-stores`)
				.then((res) => res.json())
				.then((data) => {
					if (data.success) {
						setShopStores(data.data);
					} else {
						console.error(data.message);
					}
				})
				.catch((error) => console.error("Error fetching designs:", error));
			return;
		}

		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/search-shops?q=${searchQuery}`
			);
			const result = await response.json();

			if (result.success) {
				setShopStores(result.data); // Update the designs state directly with the search results
			} else {
				console.error("Error fetching search results:", result.message);
			}
		} catch (error) {
			console.error("Failed to fetch search results", error);
		}
	};

	const openEditPanel = (store: any) => {
		setSelectedShopStore(store);

		// Extract the tag from the product name
		const tagMatch = store.product.product_name.match(/"([^"]+)"/);
		const tag = tagMatch ? tagMatch[1] : ""; // Get the captured group
		console.log(tagMatch, tag);

		setEditFormValues({
			tag: tag,
			title: store.title,
			selectedColors: store.product.product_colors.map(
				(_: any, index: any) => index
			),
			sizeOptions: store.product.product_sizes.map((size: any) => ({
				selectedSize: size,
				price: size.price.toString(),
				sale_price: size.sale_price.toString(),
				selected: true, // mark as selected
			})),
			dimensionOptions: store.product.product_dimensions.map(
				(dimension: any) => ({
					selectedSize: dimension,
					price: dimension.price.toString(),
					sale_price: dimension.sale_price.toString(),
					selected: true,
				})
			),
			name: store.name,
			createdAt: store.createdAt,
			updatedAt: store.updatedAt,
		});
		setSelectedCategory(store.categories.map((category: any) => category._id));

		setIsEditPanelOpen(true);
	};

	const updateEditFormValues = (field: any, value: any) => {
		setEditFormValues((prev: any) => ({
			...prev,
			[field]: value,
		}));
	};

	const handleSubmitEdit = async (id: any) => {
		let baseProductName = selectedShopStore.product.product_name
			.split('"')[0]
			.trim();

		const updatedData = {
			name: `${baseProductName} "${editFormValues.tag}"`,
			thumbnail: selectedShopStore.imageFile,
			categories: selectedCategory,
			title: editFormValues.title,
			product: {
				product_id: selectedShopStore.product.product_id,
				product_name: `${baseProductName} "${editFormValues.tag}"`,
				product_image: selectedShopStore.product.product_image,
				product_image_2: selectedShopStore.product.product_images_1 || null,
				product_image_3: selectedShopStore.product.product_images_2 || null,
				product_image_4: selectedShopStore.product.product_images_3 || null,
				product_image_5: selectedShopStore.product.product_images_4 || null,
				product_price: selectedShopStore.product.product_price,
				product_currency: selectedShopStore.product.product_currency,
				product_discount: selectedShopStore.product.product_discount,
				product_desc: selectedShopStore.product.product_desc,
				product_shipping_returns:
					selectedShopStore.product.product_shipping_returns,
				product_specification: selectedShopStore.product.product_specification,

				product_sizes: editFormValues.sizeOptions
					.filter((option: any) => option.selected)
					.map((option: any) => ({
						...option.selectedSize,
						price: parseFloat(option.price),
						sale_price: parseFloat(option.sale_price),
					})),
				product_dimensions: editFormValues.dimensionOptions
					.filter((option: any) => option.selected)
					.map((option: any) => ({
						...option.selectedSize,
						price: parseFloat(option.price),
						sale_price: parseFloat(option.sale_price),
					})),
				product_colors: editFormValues.selectedColors.map(
					(colorIndex: any) =>
						selectedShopStore.product.product_colors[colorIndex]
				),
			},
		};

		await handleUpdateShopStore(id, updatedData);

		setIsEditPanelOpen(false);
	};

	const handleEditColorSelect = (colorIndex: any) => {
		setEditFormValues((prev: any) => ({
			...prev,
			selectedColors: prev.selectedColors.includes(colorIndex)
				? prev.selectedColors.filter((index: any) => index !== colorIndex)
				: [...prev.selectedColors, colorIndex],
		}));
	};

	const handleEditSizeSelect = (sizeIndex: any) => {
		setEditFormValues((prev: any) => ({
			...prev,
			sizeOptions: prev.sizeOptions.map((option: any, index: any) =>
				index === sizeIndex ? { ...option, selected: !option.selected } : option
			),
		}));
	};

	const handleEditDimensionSelect = (dimensionIndex: any) => {
		setEditFormValues((prev: any) => ({
			...prev,
			dimensionOptions: prev.dimensionOptions.map((option: any, index: any) =>
				index === dimensionIndex
					? { ...option, selected: !option.selected }
					: option
			),
		}));
	};

	const confirmDeleteShopStore = async () => {
		if (deletingShopStoreId) {
			try {
				const response = await fetch(
					`${serviceConfig.apiUrl}/shop-store/${deletingShopStoreId}`,
					{
						method: "DELETE",
					}
				);
				const result = await response.json();

				if (result.success) {
					// Update the UI after deletion
					setShopStores(
						shopStores.filter((store) => store._id !== deletingShopStoreId)
					);
					setIsDeleteModalOpen(false); // Close the modal after deletion
				} else {
					console.error("Failed to delete shop store:", result.message);
				}
			} catch (error) {
				console.error("Error deleting shop store:", error);
			}
		}
	};

	const openDeleteConfirmationModal = (shopStoreId: any) => {
		setDeletingShopStoreId(shopStoreId);
		setIsDeleteModalOpen(true);
	};

	const handleCategoryClick = (id: number) => {
		console.log("Clicked category ID:", id);
		if (selectedCategory.includes(id)) {
			setSelectedCategory((prevSizes) =>
				prevSizes.filter((sizeId) => sizeId !== id)
			);
		} else {
			setSelectedCategory((prevSizes) => [...prevSizes, id]);
		}
		console.log(selectedCategory); // Log the state after updating
	};

	return (
		<div className="grid gap-8 p-8">
			<div className="grid grid-cols-maxTwo place-content-between place-items-center">
				<div className="grid gap-1">
					<Htag tag="h2" type="medium">
						Shop List
					</Htag>
					<P size="p2" type="regular" className="text-passiveTextColor">
						Track, manage and forecast your designs and orders.
					</P>
				</div>
			</div>
			<div className="grid grid-cols-1 gap-4">
				<SearchInput
					value={searchQuery}
					placeholder="Search"
					onChange={(e) => setSearchQuery(e.target.value)}
					handleSearch={handleSearch}
				/>
				{shopStores.map((store) => (
					<ShopCard
						key={store._id}
						imageSrc={
							store.product.product_colors &&
							store.product.product_colors.length > 0
								? store.product.product_colors[0].image
								: store.product.product_image
						}
						name={store.name}
						onEdit={() => openEditPanel(store)}
						onDelete={() => openDeleteConfirmationModal(store._id)}
					/>
				))}
			</div>
			{selectedShopStore && (
				<ManagePanel
					isOpen={isEditPanelOpen}
					onClose={() => setIsEditPanelOpen(false)}
					label={`Edit ${selectedShopStore.product.product_name}`}
					onSave={() => handleSubmitEdit(selectedShopStore._id)}
					onDiscard={() => setIsEditPanelOpen(false)}
				>
					<div className="grid gap-6">
						{selectedShopStore.product.product_colors &&
						selectedShopStore.product.product_colors.length > 0 ? (
							selectedShopStore.product.product_colors.length > 0 ? (
								<div className="flex flex-wrap gap-2">
									{selectedShopStore.product.product_colors.map(
										(color: any, index: any) => (
											<img
												key={index}
												src={`${serviceConfig.apiUrl}${color.image}`}
												alt={color.color}
												className="object-cover w-[126px] h-[126px] rounded-lg shadow-lg"
											/>
										)
									)}
								</div>
							) : null
						) : (
							<img
								src={`${serviceConfig.apiUrl}${selectedShopStore.product.product_image}`}
								alt={selectedShopStore.product.product_name}
								className="object-cover w-[126px] h-[126px] rounded-lg shadow-lg"
							/>
						)}

						{/* Product Tag */}
						<Input
							withlabel={true}
							appearance="default"
							type="text"
							name="tag"
							label="Tag"
							placeholder="Enter tag..."
							value={editFormValues.tag}
							onChange={(e) => updateEditFormValues("tag", e.target.value)}
						/>
						<Input
							withlabel={true}
							appearance="default"
							type="text"
							name="title"
							label="Title"
							placeholder="Enter title..."
							value={editFormValues.title}
							onChange={(e) => updateEditFormValues("title", e.target.value)}
						/>

						<div className="flex flex-wrap gap-2">
							{categoryData.map((category) => (
								<StyleManagerItem
									key={category._id}
									label={category.name}
									paddingY="py-2"
									onClick={() => handleCategoryClick(category._id)}
									className={`${
										selectedCategory.includes(category._id)
											? "bg-black text-white"
											: "bg-white text-black"
									} cursor-pointer`}
								/>
							))}
						</div>

						<Input
							withlabel={true}
							appearance="default"
							type="text"
							name="createdAt"
							label="Created Date"
							value={formatDate(editFormValues.createdAt)}
							disabled
						/>
						<Input
							withlabel={true}
							appearance="default"
							type="text"
							name="updatedAt"
							label="Updated Date"
							value={formatDate(editFormValues.updatedAt)}
							disabled
						/>

						{/* Product Colors */}
						{selectedShopStore.product.product_colors &&
							selectedShopStore.product.product_colors.length > 0 && (
								<div className="grid gap-2">
									<P size="p3" type="medium">
										Select Color
									</P>
									<div className="flex flex-wrap gap-2">
										{selectedShopStore.product.product_colors.map(
											(color: any, index: any) => (
												<StyleManagerItem
													key={index}
													label={color.color}
													onClick={() => handleEditColorSelect(index)}
													className={
														editFormValues.selectedColors.includes(index)
															? "bg-mainColor text-white"
															: ""
													}
												/>
											)
										)}
									</div>
								</div>
							)}

						{/* Size/Dimension Selection */}
						<div className="grid gap-6">
							{editFormValues.sizeOptions.map((option: any, index: any) => (
								<div key={index} className="grid gap-3">
									<P size="p3" type="medium">
										Select Size
									</P>
									<div className="flex flex-wrap gap-2">
										<StyleManagerItem
											key={index}
											label={option.selectedSize.value}
											onClick={() => handleEditSizeSelect(index)}
											className={
												option.selected ? "bg-mainColor text-white" : ""
											}
										/>
									</div>
									{option.selected && (
										<>
											<Input
												withlabel={true}
												appearance="default"
												type="text"
												name="price"
												label="Price"
												placeholder="Enter price for this size..."
												value={option.price}
												onChange={(e) => {
													const updatedSizes = editFormValues.sizeOptions.map(
														(size: any, idx: any) =>
															idx === index
																? { ...size, price: e.target.value }
																: size
													);
													setEditFormValues((prev: any) => ({
														...prev,
														sizeOptions: updatedSizes,
													}));
												}}
											/>
											<Input
												withlabel={true}
												appearance="default"
												type="text"
												name="sale_price"
												label="Sale price"
												placeholder="Enter sale price for this size..."
												value={option.sale_price}
												onChange={(e) => {
													const updatedSizes = editFormValues.sizeOptions.map(
														(size: any, idx: any) =>
															idx === index
																? { ...size, sale_price: e.target.value }
																: size
													);
													setEditFormValues((prev: any) => ({
														...prev,
														sizeOptions: updatedSizes,
													}));
												}}
											/>
										</>
									)}
								</div>
							))}

							{editFormValues.dimensionOptions.map(
								(option: any, index: any) => (
									<div key={index} className="grid gap-3">
										<P size="p3" type="medium">
											Select Dimension
										</P>
										<div className="flex flex-wrap gap-2">
											<StyleManagerItem
												key={index}
												label={option.selectedSize.value}
												onClick={() => handleEditDimensionSelect(index)}
												className={
													option.selected ? "bg-mainColor text-white" : ""
												}
											/>
										</div>
										{option.selected && (
											<>
												<Input
													withlabel={true}
													appearance="default"
													type="text"
													name="price"
													label="Price"
													placeholder="Enter price for this dimension..."
													value={option.price}
													onChange={(e) => {
														const updatedDimensions =
															editFormValues.dimensionOptions.map(
																(dim: any, idx: any) =>
																	idx === index
																		? { ...dim, price: e.target.value }
																		: dim
															);
														setEditFormValues((prev: any) => ({
															...prev,
															dimensionOptions: updatedDimensions,
														}));
													}}
												/>
												<Input
													withlabel={true}
													appearance="default"
													type="text"
													name="sale_price"
													label="Sale price"
													placeholder="Enter sale price for this dimension..."
													value={option.sale_price}
													onChange={(e) => {
														const updatedDimensions =
															editFormValues.dimensionOptions.map(
																(dim: any, idx: any) =>
																	idx === index
																		? { ...dim, sale_price: e.target.value }
																		: dim
															);
														setEditFormValues((prev: any) => ({
															...prev,
															dimensionOptions: updatedDimensions,
														}));
													}}
												/>
											</>
										)}
									</div>
								)
							)}
						</div>
					</div>
				</ManagePanel>
			)}
			<Modal
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
				title="Delete Shop Store"
				desc="Are you sure you want to delete this shop store? This action cannot be undone."
				onConfirm={confirmDeleteShopStore}
				onCancel={() => setIsDeleteModalOpen(false)}
			/>
		</div>
	);
};

export default ShopListPage;
