import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { Button } from "common/Button/Button";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Input } from "common/Input/Input";
import Textarea from "common/Textarea/Textarea";
import Modal from "common/Modal/Modal";
import DesignCard from "components/DesignCard/DesignCard";
import ImageUpload from "common/Image/ImageUpload";
import StyleManagerItem from "common/StyleManagerItem/StyleManagerItem";
import { SearchInput } from "common/Search/Search";
import { serviceConfig } from "configs";
import formatDate from "utils/formatDate";

type Design = {
	_id: number;
	name: string;
	image: string;
	prompt: string;
	negativePrompt: string;
	model: string;
	categories: string[];
	createdAt: string;
	updatedAt: string;
};

type Category = {
	_id: number;
	name: string;
};

const DesignList: React.FC = () => {
	const [designs, setDesigns] = useState<Design[]>([]);
	const [categoryData, setCategoryData] = useState<Category[]>([]);
	const [isManagePanelOpen, setIsManagePanelOpen] = useState(false);
	const [currentDesign, setCurrentDesign] = useState<any | null>(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deletingDesignId, setDeletingDesignId] = useState<number | null>(null);
	const [uploadedImageSrc, setUploadedImageSrc] = useState<
		string | undefined
	>();
	const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
	const [formValues, setFormValues] = useState({
		name: "",
		prompt: "",
		negativePrompt: "",
		modelType: "",
		createdAt: "",
		updatedAt: "",
	});
	const [searchQuery, setSearchQuery] = useState<string>("");

	useEffect(() => {
		// Fetch designs
		fetch(`${serviceConfig.apiUrl}/design`)
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setDesigns(data.data);
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error fetching designs:", error));

		// Fetch categories
		fetch(`${serviceConfig.apiUrl}/category`)
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setCategoryData(data.data);
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error fetching categories:", error));
	}, []);

	const handleAddDesign = () => {
		setSelectedCategory([]);
		setCurrentDesign(null); // reset current design
		setFormValues({
			// reset form values
			name: "",
			prompt: "",
			negativePrompt: "",
			modelType: "",
			createdAt: "",
			updatedAt: "",
		});
		setIsManagePanelOpen(true); // open the ManagePanel
	};

	const handleEditDesign = (design: Design) => {
		setCurrentDesign(design);
		setFormValues({
			// populate form values with design data
			name: design.name,
			prompt: design.prompt,
			negativePrompt: design.negativePrompt,
			modelType: design.model,
			createdAt: design.createdAt,
			updatedAt: design.updatedAt,
		});
		setSelectedCategory(design.categories.map((category: any) => category._id)); // Set the categories of the design being edited
		setIsManagePanelOpen(true);
	};

	const closeManagePanel = () => {
		setCurrentDesign(null);
		setSelectedCategory([]);
		setIsManagePanelOpen(false);
	};

	const editDesign = (design: Design) => {
		const formData = new FormData();

		if (uploadedImageSrc) {
			formData.append("image", uploadedImageSrc);
		}

		// Use formValues for field values
		if (formValues.name) {
			formData.append("name", formValues.name);
		}

		if (formValues.prompt) {
			formData.append("prompt", formValues.prompt);
		}

		if (formValues.negativePrompt) {
			formData.append("negativePrompt", formValues.negativePrompt);
		}

		if (formValues.modelType) {
			formData.append("model", formValues.modelType);
		}

		selectedCategory.forEach((categoryId: any) => {
			formData.append("categories[]", categoryId);
		});

		// Use design's _id for the endpoint URL
		fetch(`${serviceConfig.apiUrl}/design/${design._id}`, {
			method: "PUT", // Indicate an update with PUT method
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setDesigns((prevDesigns) =>
						prevDesigns.map((d) => (d._id === design._id ? data.data : d))
					);
					window.location.reload();
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error updating design:", error));

		setIsManagePanelOpen(false);
	};

	const submitDesign = () => {
		if (currentDesign) {
			// Editing an existing design
			editDesign(currentDesign);
		} else {
			// Adding a new design
			const formData = new FormData();

			if (uploadedImageSrc) {
				formData.append("image", uploadedImageSrc);
			}

			if (formValues.name) {
				formData.append("name", formValues.name);
			}

			if (formValues.prompt) {
				formData.append("prompt", formValues.prompt);
			}

			if (formValues.negativePrompt) {
				formData.append("negativePrompt", formValues.negativePrompt);
			}

			if (formValues.modelType) {
				formData.append("model", formValues.modelType);
			}

			selectedCategory.forEach((categoryId: any) => {
				formData.append("categories[]", categoryId);
			});

			fetch(`${serviceConfig.apiUrl}/design`, {
				method: "POST",
				body: formData,
			})
				.then((res) => res.json())
				.then((data) => {
					if (data.success) {
						setDesigns((prevDesigns) => [...prevDesigns, data.data]);
						window.location.reload();
					} else {
						console.error(data.message);
					}
				})
				.catch((error) => console.error("Error adding design:", error));

			setIsManagePanelOpen(false);
		}
	};

	const handleDeleteDesign = (designId: number) => {
		setDeletingDesignId(designId);
		setIsDeleteModalOpen(true);
	};

	const confirmDeleteDesign = () => {
		fetch(`${serviceConfig.apiUrl}/design/${deletingDesignId}`, {
			method: "DELETE",
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setDesigns(
						designs.filter((design) => design._id !== deletingDesignId)
					);
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error deleting design:", error));

		setIsDeleteModalOpen(false);
	};

	const handleCategoryClick = (id: number) => {
		console.log("Clicked category ID:", id);
		if (selectedCategory.includes(id)) {
			setSelectedCategory((prevSizes) =>
				prevSizes.filter((sizeId) => sizeId !== id)
			);
		} else {
			setSelectedCategory((prevSizes) => [...prevSizes, id]);
		}
		console.log(selectedCategory); // Log the state after updating
	};

	const handleSearch = async () => {
		if (searchQuery.length === 0) {
			// If the search input is empty, fetch all designs
			fetch(`${serviceConfig.apiUrl}/design`)
				.then((res) => res.json())
				.then((data) => {
					if (data.success) {
						setDesigns(data.data);
					} else {
						console.error(data.message);
					}
				})
				.catch((error) => console.error("Error fetching designs:", error));
			return;
		}

		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/design/search?q=${searchQuery}`
			);
			const result = await response.json();

			if (result.success) {
				setDesigns(result.data); // Update the designs state directly with the search results
			} else {
				console.error("Error fetching search results:", result.message);
			}
		} catch (error) {
			console.error("Failed to fetch search results", error);
		}
	};

	return (
		<div className="grid gap-8 p-8">
			<div className="grid grid-cols-maxTwo place-content-between place-items-center">
				<div className="grid gap-1">
					<Htag tag="h2" type="medium">
						Design List
					</Htag>
					<P size="p2" type="regular" className="text-passiveTextColor">
						Track, manage and forecast your designs and orders.
					</P>
				</div>
				<Button
					appearance="black"
					icon={<PlusIcon />}
					onClick={handleAddDesign}
				>
					Add Design
				</Button>
			</div>
			<div className="grid grid-cols-1 gap-4">
				<SearchInput
					value={searchQuery}
					placeholder="Search"
					onChange={(e) => setSearchQuery(e.target.value)}
					handleSearch={handleSearch}
				/>
				{designs.map((design) => (
					<DesignCard
						key={design._id}
						imageSrc={design.image}
						prompt={design.prompt}
						negativePrompt={design.negativePrompt}
						modelType={design.model}
						categories={design.categories}
						onEdit={() => handleEditDesign(design)}
						onDelete={() => handleDeleteDesign(design._id)}
					/>
				))}
			</div>
			<ManagePanel
				isOpen={isManagePanelOpen}
				onClose={closeManagePanel}
				label={currentDesign ? "Edit Design" : "Add Design"}
				onSave={submitDesign} // <-- Updated to use the new submitDesign function
				onDiscard={closeManagePanel}
			>
				<div className="grid gap-6">
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="name"
						label="Name"
						placeholder="Enter name..."
						value={formValues.name}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								name: e.target.value,
							}))
						}
					/>
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="prompt"
						label="Prompt"
						placeholder="Enter prompt..."
						value={formValues.prompt}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								prompt: e.target.value,
							}))
						}
					/>

					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="negative_prompt"
						label="Negative prompt"
						placeholder="Enter negative prompt..."
						value={formValues.negativePrompt}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								negativePrompt: e.target.value,
							}))
						}
					/>
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="model"
						label="Model"
						placeholder="Enter model..."
						value={formValues.modelType}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								modelType: e.target.value,
							}))
						}
					/>

					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="createdAt"
						label="Created Date"
						value={formatDate(formValues.createdAt)}
						disabled
					/>

					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="updatedAt"
						label="Updated Date"
						value={formatDate(formValues.updatedAt)}
						disabled
					/>
					<div className="flex flex-wrap gap-2">
						{categoryData.map((category) => (
							<StyleManagerItem
								key={category._id}
								label={category.name}
								paddingY="py-2"
								onClick={() => handleCategoryClick(category._id)}
								className={`${
									selectedCategory.includes(category._id)
										? "bg-black text-white"
										: "bg-white text-black"
								} cursor-pointer`}
							/>
						))}
					</div>

					<ImageUpload
						singleUpload={true}
						onImageUpload={setUploadedImageSrc}
					/>
				</div>
			</ManagePanel>
			<Modal
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
				title="Delete Design"
				desc="Are you sure you want to delete this design? This action cannot be undone."
				onConfirm={confirmDeleteDesign}
				onCancel={() => setIsDeleteModalOpen(false)}
			/>
		</div>
	);
};

export default DesignList;
