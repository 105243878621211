import React, { useLayoutEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "common/assets/icons/x.svg";
import { ReactComponent as AlertIcon } from "common/assets/icons/alert.svg";
import { Htag } from "common/Htag/Htag";
import { Button } from "common/Button/Button";
import { motion } from "framer-motion";

import { ImageUploadModalProps } from "./ImageUploadModal.props";
import { P } from "common/P/P";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UploadSvg } from "common/assets/icons/image_uploadsvg.svg";
import { Input } from "common/Input/Input";
import { serviceConfig } from "configs";
import { routeConstants } from "navigation";
import { Spinner } from "modules/loaders";

const Modal: React.FC<ImageUploadModalProps> = ({
	isOpen,
	onClose,
	title = "Upload New Image",
	width,
}) => {
	const [file, setFile] = useState<File | null>(null);
	const [imageUrl, setImageUrl] = useState("");
	const [uploadProgress, setUploadProgress] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	useLayoutEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.removeProperty("overflow");
		};
	}, [isOpen]);

	const modalVariants = {
		open: { opacity: 1, transition: { duration: 0.3 } },
		closed: { opacity: 0, transition: { duration: 0.3 } },
	};

	const handleFileChange = (event: any) => {
		const file = event.target.files[0];
		if (file) {
			setFile(file);
			setImageUrl(""); // Clear the image URL if a file is uploaded
		}
	};

	const handleImageUrlChange = (event: any) => {
		setImageUrl(event.target.value);
		setFile(null); // Clear the file if an image URL is entered
	};

	const handleUpload = async () => {
		setIsLoading(true);
		const formData = new FormData();

		if (file) {
			// Append the file to the FormData object
			formData.append("image", file);
		} else if (imageUrl) {
			// If it's an image URL, send it as a JSON payload
			formData.append("imageUrl", imageUrl);
		} else {
			console.error("No image or URL provided");
			return;
		}

		try {
			const response = await fetch(`${serviceConfig.apiUrl}/image-to-shop`, {
				method: "POST",
				body: formData,
			});

			const result = await response.json();
			if (result.success) {
				navigate(routeConstants.mockupedProducts.route, {
					state: { mockups: result.data },
				});
				setIsLoading(false);
			} else {
				// Handle error in response
				console.error("Error uploading image:", result.message);
				setIsLoading(false);
			}
		} catch (error) {
			console.error("Error uploading image:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleDeleteImage = () => {
		setFile(null);
		setImageUrl("");
	};

	return (
		<>
			<div
				className={`${
					isOpen ? "fixed inset-0 z-40" : "hidden"
				} backdrop-blur-sm bg-[rgba(47,47,47,0.65)]`}
				onClick={onClose}
			></div>
			{isOpen && (
				<motion.div
					initial="closed"
					animate="open"
					exit="closed"
					variants={modalVariants}
					className={`fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 ${
						width ? width : "w-[610px]"
					} bg-whiteColor flex flex-col z-50 rounded-lg shadow-lg`}
				>
					<div className="grid items-center gap-4 px-6 py-6 bg-white rounded-t-lg grid-cols-frMax">
						<Htag tag="h4" type="medium" className="text-center cursor-default">
							{title}
						</Htag>
						<CloseIcon onClick={onClose} className="cursor-pointer" />
					</div>
					<div className="grid gap-3">
						<div className="grid flex-grow gap-3 px-6 py-6 overflow-y-auto place-items-center">
							<label
								htmlFor="file-upload"
								className={`border-2 rounded-lg border-dashed w-full h-full flex flex-col items-center justify-center cursor-pointer p-6 ${
									imageUrl !== "" ? "opacity-50 cursor-not-allowed" : ""
								}`}
							>
								<div className="grid gap-3 place-items-center place-content-center">
									<UploadSvg />
									<div className="grid gap-1 place-content-center place-items-center">
										<P size="p1" type="medium" className="">
											Upload a file
										</P>
										<P
											size="p3"
											type="regular"
											className="text-passiveTextColor"
										>
											Only jpeg, png and jpg files are supported
										</P>
									</div>
								</div>
								{imageUrl === "" && (
									<input
										id="file-upload"
										type="file"
										onChange={handleFileChange}
										accept=".jpg, .jpeg, .png"
										className="hidden"
									/>
								)}
							</label>
							<P size="p3" type="medium" className="text-passiveTextColor">
								or
							</P>
							<Input
								type="text"
								placeholder="Enter an image url"
								value={imageUrl}
								onChange={handleImageUrlChange}
								disabled={file !== null}
								appearance="default"
								name="image_link"
							/>
							{file && (
								<div
									className={`grid w-full p-3 border rounded-lg ${
										uploadProgress > 0
											? "grid-cols-maxThree"
											: "grid-cols-maxTwo"
									} place-content-between place-items-center border-strokeColor`}
								>
									<div
										className={`grid gap-1 grid-cols-maxTwo place-items-center`}
									>
										<P size="t1" type="medium" className="">
											{file.name.slice(0, 35)}
										</P>
										<P
											size="t1"
											type="medium"
											className="text-passiveTextColor"
										>
											({Math.round(file.size / 1024)} Kb)
										</P>
									</div>
									{uploadProgress > 0 && (
										<div className="grid items-center w-full gap-2 grid-cols-maxTwo">
											<div className="relative min-w-[80px] w-full h-2 overflow-hidden border border-strokeColor rounded bg-strokeColor">
												<div
													className="h-full bg-black rounded-full"
													style={{
														width: `${uploadProgress}%`,
													}}
												></div>
											</div>
											<P
												size="t1"
												type="regular"
												className="text-passiveTextColor"
											>
												{uploadProgress}%
											</P>
										</div>
									)}

									<CloseIcon
										onClick={handleDeleteImage}
										className="cursor-pointer"
									/>
								</div>
							)}

							{/* Generate Button */}
							{isLoading ? (
								<Spinner />
							) : (
								<Button onClick={handleUpload} appearance="black">
									Generate
								</Button>
							)}
						</div>
					</div>
				</motion.div>
			)}
		</>
	);
};

export default Modal;
