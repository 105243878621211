import React from "react";
import { EmptyStateProps } from "./EmptyState.props";
import { P } from "common/P/P";
import { ReactComponent as EmptyIcon } from "common/assets/icons/empty_icon.svg";

const EmptyState: React.FC<EmptyStateProps> = ({
	icon,
	title,
	description,
	className = "place-content-center",
}) => {
	return (
		<div className={`grid gap-4 place-items-center ${className}`}>
			{!icon ? (
				<div className="p-3 bg-[#D7D7D7] border-spacing-2 rounded-[28px] border-[#FAFAFA]">
					<EmptyIcon />
				</div>
			) : (
				icon
			)}

			<div className="grid w-8/12 gap-1 place-items-center">
				<P size="p2" type="semibold">
					{title}
				</P>
				{description && (
					<P
						size="p2"
						type="regular"
						className="text-center text-passiveTextColor"
					>
						{description}
					</P>
				)}
			</div>
		</div>
	);
};

export default EmptyState;
