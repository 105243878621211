import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import Pagination from "components/Pagination/Pagination";
import UserTable from "components/UserTable/UserTable";
import { serviceConfig } from "configs";
import ManagePanel from "common/ManagePanel/ManagePanel";
import Modal from "common/Modal/Modal";
import OrderHistoryTable from "components/OrderHistoryTable/OrderHistoryTable";
import { useParams } from "react-router-dom";
import formatDate from "utils/formatDate";

const data = [
	{
		id: 1,
		user: "Mekan Hojaberdiyew",
		phone_number: "+99362665444",
		email: "johnjoseph@gmail.com",
		address: "4517 Washington Ave. Manchester, Kentucky 39495",
		orders: [
			{
				id: 1,
				products: [
					{
						id: 1,
						name: "T-shirt",
					},
					{
						id: 2,
						name: "Canvas",
					},
				],
				address: "4517 Washington Ave. Manchester, Kentucky 39495",
				status: "Delivered",
				price: "500 $",
			},
			{
				id: 2,
				products: [
					{
						id: 1,
						name: "T-shirt",
					},
					{
						id: 2,
						name: "Canvas",
					},
				],
				address: "4517 Washington Ave. Manchester, Kentucky 39495",
				status: "Preparing",
				price: "500 $",
			},
			{
				id: 3,
				products: [
					{
						id: 1,
						name: "T-shirt",
					},
					{
						id: 2,
						name: "Canvas",
					},
				],
				address: "4517 Washington Ave. Manchester, Kentucky 39495",
				status: "Waiting",
				price: "500 $",
			},
			{
				id: 4,
				products: [
					{
						id: 1,
						name: "T-shirt",
					},
					{
						id: 2,
						name: "Canvas",
					},
				],
				address: "4517 Washington Ave. Manchester, Kentucky 39495",
				status: "Cancelled",
				price: "500 $",
			},
		],
	},
];

const UserManagementDetailPage: React.FC = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [waitlists, setWaitlists] = useState([]);
	const [loading, setLoading] = useState(true);
	const [totalPages, setTotalPages] = useState(0);
	const itemsPerPage = 8;
	const [userData, setUserData] = useState<any>(null);
	const { id } = useParams<{ id: string }>();

	console.log("HEHE", userData, id);

	useEffect(() => {
		const fetchUserDetails = async () => {
			try {
				const response = await fetch(`${serviceConfig.apiUrl}/users/${id}`);
				const result = await response.json();

				if (!result.data) {
					console.error("User data not found");
					setLoading(false);
					return;
				}

				const transformedData = {
					id: result.data._id,
					user: `${result.data.RpAccName}`,
					phone_number:
						result.data.addresses &&
						result.data.addresses.length > 0 &&
						result.data.addresses[0].phone_number,
					email: result.data.RpAccEMail,
					createdAt: result.data.createdAt,
					address:
						result.data.addresses &&
						result.data.addresses.length > 0 &&
						formatAddress(result.data.addresses[0]),

					orders: result.data.orders.map((order: any) => ({
						id: order._id,
						products: order.items.map((item: any) => ({
							id: item.product_id,
							name: item.product_name,
						})),
						address: order.address1,
						status: order.status,
						price: `$${order.total}`,
					})),
				};

				setUserData(transformedData);
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch user details", error);
				setLoading(false);
			}
		};

		fetchUserDetails();
	}, [id]);

	const formatAddress = (address: any) => {
		const { address: street, city, state, zip, country } = address;
		return `${street}, ${city}, ${state} ${zip}, ${country}`;
	};

	const currentWaitlists = waitlists.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	return (
		<div className="grid gap-8 p-8">
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					User Management
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					Track, manage and forecast your customers and orders.
				</P>
			</div>
			<div className="grid gap-6">
				{loading ? (
					<p>Loading...</p>
				) : (
					<>
						<div className="border border-strokeColor">
							<Htag
								tag="h4"
								type="semibold"
								className="p-6 border-b text-passiveTextColor border-b-strokeColor"
							>
								User Info
							</Htag>
							<div className="grid grid-cols-3 gap-6 p-6">
								<div className="grid gap-2">
									<P size="p2" type="regular" className="text-passiveTextColor">
										ID
									</P>
									<P size="p1" type="medium">
										{userData?.id}
									</P>
								</div>
								<div className="grid gap-2">
									<P size="p2" type="regular" className="text-passiveTextColor">
										Name
									</P>
									<P size="p1" type="medium">
										{userData?.user}
									</P>
								</div>
								<div className="grid gap-2">
									<P size="p2" type="regular" className="text-passiveTextColor">
										Phone Number
									</P>
									<P size="p1" type="medium">
										{userData?.phone_number || "No number"}
									</P>
								</div>
								<div className="grid gap-2">
									<P size="p2" type="regular" className="text-passiveTextColor">
										Email
									</P>
									<P size="p1" type="medium">
										{userData?.email}
									</P>
								</div>
								<div className="grid gap-2">
									<P size="p2" type="regular" className="text-passiveTextColor">
										Joined Date
									</P>
									<P size="p1" type="medium">
										{formatDate(userData?.createdAt)}
									</P>
								</div>
								{userData?.address && (
									<div className="grid gap-2">
										<P
											size="p2"
											type="regular"
											className="text-passiveTextColor"
										>
											Address
										</P>
										<P size="p1" type="medium">
											{userData?.address}
										</P>
									</div>
								)}
							</div>
						</div>
						{/* Order history */}
						{userData?.orders?.length > 0 && (
							<div className="border border-strokeColor">
								<Htag
									tag="h4"
									type="semibold"
									className="p-6 border-b text-passiveTextColor border-b-strokeColor"
								>
									Order history
								</Htag>
								<OrderHistoryTable data={userData.orders} />
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default UserManagementDetailPage;
