import React from "react";
import { DesignCardProps } from "./DesignCard.props";
import Actions from "common/Actions/Actions";
import { P } from "common/P/P";
import { serviceConfig } from "configs";

const DesignCard: React.FC<DesignCardProps> = ({
	imageSrc,
	prompt,
	negativePrompt,
	modelType,
	categories,
	onEdit,
	onDelete,
}) => {
	return (
		<div className="grid w-full px-4 py-3 bg-white border rounded-lg shadow-xs grid-cols-maxTwo place-content-between place-items-center border-strokeColor">
			<div className="grid gap-4 grid-cols-maxTwo place-items-start">
				<img
					src={`${serviceConfig.apiUrl}${imageSrc}`}
					alt={prompt}
					className="object-cover w-24 h-24 rounded-md"
				/>
				<div>
					<div className="grid gap-2 grid-cols-maxTwo">
						<P size="p2" type="medium">
							Prompt:
						</P>
						<P size="p2" type="regular">
							{prompt}
						</P>
					</div>
					<div className="grid gap-2 grid-cols-maxTwo">
						<P size="p2" type="medium">
							Negative prompt:
						</P>
						<P size="p2" type="regular">
							{negativePrompt}
						</P>
					</div>
					<div className="grid gap-2 grid-cols-maxTwo">
						<P size="p2" type="medium">
							Model:
						</P>
						<P size="p2" type="regular">
							{modelType}
						</P>
					</div>
					<div className="grid gap-2 grid-cols-maxTwo">
						<P size="p2" type="medium">
							Categories:
						</P>
						<div className="flex flex-wrap gap-2">
							{categories.map((category: any, index) => (
								<P size="p2" type="regular" key={index}>
									{category.name}
									{index !== categories.length - 1 && " , "}
								</P>
							))}
						</div>
					</div>
				</div>
			</div>
			<Actions onEdit={onEdit} onDelete={onDelete} />
		</div>
	);
};

export default DesignCard;
