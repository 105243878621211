import React from "react";

export interface SelectItemProps {
	value: string;
	onSelect?: (value: string) => void;
	children: React.ReactNode;
	className?: string;
}

export const SelectItem: React.FC<SelectItemProps> = ({
	value,
	onSelect,
	children,
	className,
}) => {
	return (
		<div
			onClick={() => onSelect && onSelect(value)}
			className={`px-4 py-3 cursor-pointer first:rounded-t-lg last:rounded-b-lg hover:bg-hoverColor ${
				className ? className : "text-textColor text-sm font-medium"
			}`}
		>
			{children}
		</div>
	);
};
