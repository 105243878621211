import React from "react";
import "./ToggleSwitch.css";

interface ToggleSwitchProps {
	isActive: boolean;
	onToggle: (isActive: boolean) => void;
	label?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
	isActive,
	onToggle,
	label,
}) => {
	return (
		<div className="grid gap-2 toggle-switch-container grid-cols-maxTwo place-items-center">
			<label className="switch">
				<input
					type="checkbox"
					checked={isActive}
					onChange={() => onToggle && onToggle(!isActive)}
				/>
				<span className="slider round"></span>
			</label>
			{label && <label className="toggle-switch-label">{label}</label>}
		</div>
	);
};

export default ToggleSwitch;
