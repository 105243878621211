import React, { useState } from "react";
import { Input } from "common/Input/Input";
import { Htag } from "common/Htag/Htag";
import { Button } from "common/Button/Button";
import { ReactComponent as Logo } from "common/assets/icons/logo.svg";
import { useNavigate } from "react-router-dom";
import { serviceConfig } from "configs";

const LoginPage: React.FC = () => {
	const [username, setUsername] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	const navigate = useNavigate();

	const handleLogin = async () => {
		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}${serviceConfig.routes.admin_login}`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ username, password }),
				}
			);
			const data = await response.json();
			if (data.error) {
				console.error("Login failed", data.error);
				return;
			}
			localStorage.setItem("token", data.data.token);
			localStorage.setItem("user", data.data.username);
			navigate("/");
		} catch (error) {
			console.error("Login failed", error);
		}
	};

	return (
		<div className="grid">
			<div>
				<div className="px-6 py-8 border-b cursor-default border-strokeColor">
					<Logo />
				</div>
				<div className="grid min-h-[85vh] gap-10 place-content-center grid-rows-maxTwo bg-whiteColor">
					<Htag tag="h2" type="semibold" className="min-w-[25rem]">
						Log in
					</Htag>
					<div className="grid gap-6">
						<Input
							withlabel={true}
							appearance="default"
							type="text"
							name="name"
							label="Username"
							placeholder="username"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>

						<Input
							withlabel={true}
							appearance="default"
							type="password"
							name="name"
							label="Password"
							placeholder="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<Button appearance="black" onClick={handleLogin} width="w-full">
							Log in
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
