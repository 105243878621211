import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { ReactComponent as BellIcon } from "common/assets/icons/bell.svg";
import { ReactComponent as ChartIcon } from "common/assets/icons/chart-pie-2.svg";
import { ReactComponent as UsersIcon } from "common/assets/icons/users.svg";
import { ReactComponent as TickIcon } from "common/assets/icons/check.svg";
import { motion } from "framer-motion";
import { serviceConfig } from "configs";

type Notification = {
	_id: string;
	message: string;
	isRead: boolean;
	date: string;
	time: string;
};

const DashboardPage: React.FC = () => {
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const [isPanelOpen, setIsPanelOpen] = useState(false);
	const [orderCount, setOrderCount] = useState<number>(0);
	const [userCount, setUserCount] = useState<number>(0);

	// Fetch order count
	const fetchOrderCount = async () =>
		await fetch(`${serviceConfig.apiUrl}/order-count`)
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setOrderCount(data.data.count);
				}
			})
			.catch((err) => console.error(err));

	// Fetch user count
	const fetchUserCount = async () =>
		await fetch(`${serviceConfig.apiUrl}/user-count`)
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setUserCount(data.data.count);
				}
			})
			.catch((err) => console.error(err));

	// Fetch notifications
	const fetchNotification = async () =>
		fetch(`${serviceConfig.apiUrl}/notifications`)
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setNotifications(data.data);
				}
			})
			.catch((err) => console.error(err));

	useEffect(() => {
		fetchOrderCount();
		fetchUserCount();
		fetchNotification();
	}, []);

	const handleNotificationClick = (id: string) => {
		fetch(`${serviceConfig.apiUrl}/notifications/${id}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ isRead: true }), // setting read status to true
		})
			.then((res) => {
				if (!res.ok) {
					throw new Error("Network response was not ok");
				}

				return res.json();
			})
			.then((updatedNotification: Notification) => {
				// Update the local state immediately
				setNotifications((prevNotifications) =>
					prevNotifications.map((notif) =>
						notif._id === updatedNotification._id ? updatedNotification : notif
					)
				);
				fetchNotification();
			})
			.catch((err) => console.error(err));
	};

	const unreadNotifications = notifications.filter((notif) => !notif.isRead);

	const sortedNotifications = notifications.sort(
		(a, b) => Date.parse(b.date) - Date.parse(a.date)
	);

	let displayedPreviousHeader = false;

	const panelVariants = {
		open: {
			opacity: 1,
			x: 0,
			transition: {
				type: "spring",
				stiffness: 300,
				damping: 30,
			},
		},
		closed: {
			opacity: 0,
			x: "100%",
			transition: {
				type: "spring",
				stiffness: 300,
				damping: 30,
			},
		},
	};

	return (
		<div className="grid gap-8 p-8">
			<div className="grid gap-1 grid-cols-frMax">
				<div>
					<Htag tag="h2" type="medium">
						Dashboard
					</Htag>
					<P size="p2" type="regular" className="text-passiveTextColor">
						Track, manage and forecast your customers and orders.
					</P>
				</div>
				<div className="relative">
					<div
						onClick={() => setIsPanelOpen(!isPanelOpen)}
						className="p-3 bg-white rounded-md cursor-pointer shadow-graySm w-max h-max place-self-end"
					>
						<div className="relative">
							<BellIcon />
							{notifications.some((notif) => !notif.isRead) && (
								<div className="absolute bg-[#28C76F] top-[-2px] right-[3px] w-2 h-2 rounded-full"></div>
							)}
						</div>
					</div>
					{isPanelOpen && (
						<motion.div
							initial="closed"
							animate="open"
							exit="closed"
							variants={panelVariants}
							className="absolute bg-white rounded-md shadow-graySm right-0 w-[375px] max-h-[609px] overflow-y-auto overflow-x-hidden mt-1"
						>
							<div className="px-5 py-4 border-b border-strokeColor">
								<P size="p1" type="medium">
									Notifications
								</P>
							</div>
							<div className="grid gap-2 p-4">
								{sortedNotifications.map((notif, index, array) => (
									<React.Fragment key={notif._id}>
										{notif.isRead && !displayedPreviousHeader && (
											<div className="flex items-center my-4">
												<div className="flex-1 border-t border-strokeColor"></div>
												<P
													size="p3"
													type="medium"
													className="mx-4 text-center text-passiveTextColor"
												>
													Previous Notifications
												</P>
												<div className="flex-1 border-t border-strokeColor"></div>
											</div>
										)}
										{
											(displayedPreviousHeader =
												notif.isRead || displayedPreviousHeader)
										}
										<div
											onClick={() => handleNotificationClick(notif._id)}
											className="relative grid gap-2 p-2 border rounded-md border-strokeColor"
										>
											<div className="grid gap-4 grid-cols-frMax">
												<P size="p2" type="regular">
													{notif.message}
												</P>
												{notif.isRead ? (
													<div className="w-2 h-2 m-2 rounded-full bg-slate-400"></div>
												) : (
													<div className="bg-[#28C76F] w-2 h-2 m-2 rounded-full"></div>
												)}
											</div>

											<P
												size="p2"
												type="regular"
												className="text-passiveTextColor"
											>
												{notif.date} {notif.time}
											</P>
										</div>
									</React.Fragment>
								))}
							</div>
						</motion.div>
					)}
				</div>
			</div>
			<div className="grid w-full gap-8 p-6 rounded-md shadow-lightGray">
				<Htag tag="h4" type="medium">
					Statistics overview
				</Htag>
				<div className="grid grid-flow-col gap-32 auto-cols-max">
					<div className="grid gap-5 grid-cols-maxTwo place-items-center">
						<ChartIcon />
						<div className="grid gap-1">
							<Htag tag="h4" type="medium">
								{orderCount}
							</Htag>
							<P size="p2" type="regular" className="text-passiveTextColor">
								Sales
							</P>
						</div>
					</div>
					<div className="grid gap-5 grid-cols-maxTwo place-items-center">
						<UsersIcon />
						<div className="grid gap-1">
							<Htag tag="h4" type="medium">
								{userCount}
							</Htag>
							<P size="p2" type="regular" className="text-passiveTextColor">
								User activity
							</P>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DashboardPage;
