import React from "react";
import { P } from "common/P/P";
import { ReactComponent as UploadIcon } from "common/assets/icons/upload.svg";

interface ImageUploadButtonProps {
	onChange: (imageFile: File | undefined) => void;
}

export const ImageUploadButton: React.FC<ImageUploadButtonProps> = ({
	onChange,
}) => {
	const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			const selectedFile = e.target.files[0];
			onChange(selectedFile);
		} else {
			onChange(undefined);
		}
	};

	return (
		<div className="grid gap-2">
			<P size="p3" type="medium" className="text-passiveTextColor">
				Picture
			</P>
			<div className="grid w-full grid-flow-row px-4 py-3 border rounded-lg cursor-pointer border-strokeColor place-items-center place-content-start">
				<div className="grid gap-2 px-4 py-2 border rounded grid-cols-maxTwo place-content-center place-items-center shadow-graySm border-mainColor">
					<UploadIcon />
					<label htmlFor="file-upload">
						<input
							id="file-upload"
							className="hidden"
							type="file"
							onChange={onSelectFile}
							accept="image/*"
						/>
						<P size="p3" type="medium" className="cursor-pointer">
							Upload picture
						</P>
					</label>
				</div>
			</div>
		</div>
	);
};

export default ImageUploadButton;
