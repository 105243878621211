import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import Pagination from "components/Pagination/Pagination";

import { serviceConfig } from "configs";
import SubscribeTable from "components/SubscribeTable/SubscribeTable";

const SubscribePage: React.FC = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [subscriptions, setSubscriptions] = useState([]);
	const [loading, setLoading] = useState(true);
	const [totalPages, setTotalPages] = useState(0);
	const itemsPerPage = 8;

	useEffect(() => {
		const fetchSubscriptions = async () => {
			setLoading(true);
			try {
				const response = await fetch(`${serviceConfig.apiUrl}/subscriptions`);
				const result = await response.json();
				if (response.ok && result.success) {
					setSubscriptions(result.data);
					setTotalPages(Math.ceil(result.data.length / itemsPerPage));
				} else {
					console.error("Failed to fetch subscriptions", result.message);
				}
			} catch (error) {
				console.error("Failed to fetch subscriptions", error);
			}
			setLoading(false);
		};

		fetchSubscriptions();
	}, []);

	const currentWaitlists = subscriptions.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	return (
		<div className="p-8">
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					Subscribe
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					Tracking the subscribed of users.
				</P>
			</div>
			<div className="grid gap-6">
				{loading ? (
					<p>Loading...</p>
				) : (
					<>
						<SubscribeTable data={currentWaitlists} className="mt-8" />
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={setCurrentPage}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default SubscribePage;
