import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as ArrowDownIcon } from "common/assets/icons/chevron-down.svg";
import { SelectProps } from "./Select.props";
import { SelectItemProps } from "./SelectItem";

export const Select: React.FC<SelectProps> = ({
	label,
	children,
	defaultSelectLabel = "Select property",
	className,
	disabled = false,
	onSelect,
	selectedLabel,
}) => {
	console.log("coming selected lable", selectedLabel);
	const [isOpen, setIsOpen] = useState(false);

	const dropdownRef = useRef<HTMLDivElement>(null);

	const getStatusClass = (status: string) => {
		const loweredStatus = status.toLowerCase();
		switch (loweredStatus) {
			case "draft":
				return "text-[#9E9E9E]"; // Gray for draft
			case "ordered":
				return "text-[#FFC107]"; // Amber for ordered
			case "shipped":
				return "text-[#BF310A]"; // Custom color for shipped
			case "delivered":
				return "text-[#2DAA6F]"; // Green for delivered
			case "pending":
				return "text-[#FE9A04]"; // Orange for pending
			default:
				return "";
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleClickOutside = (e: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(e.target as Node)
		) {
			setIsOpen(false);
		}
	};

	const handleItemSelect = (value: string, label: string) => {
		if (onSelect) {
			onSelect(value); // Pass the selected value to the parent component
		}
		setIsOpen(false);
	};

	return (
		<div
			className={`relative grid gap-2 ${disabled ? "opacity-60" : ""}`}
			ref={dropdownRef}
		>
			{label && (
				<label className="block text-sm font-medium text-textColor">
					{label}
				</label>
			)}
			<div
				onClick={() => !disabled && setIsOpen(!isOpen)}
				className={`grid items-center w-full text-sm font-medium px-4 py-3 transition-all duration-300 ease-in-out border rounded-lg cursor-pointer ${
					disabled ? "cursor-not-allowed" : ""
				} grid-cols-frMax border-strokeColor bg-whiteColor duration-400 hover:border-black focus:border-black focus:outline-none ${
					selectedLabel && getStatusClass(selectedLabel)
				}`}
			>
				{selectedLabel || defaultSelectLabel}
				<ArrowDownIcon className="stroke-passiveTextColor" />
			</div>
			{isOpen && !disabled && (
				<div className="absolute z-10 w-full mt-1 transition-all duration-300 border rounded-lg shadow-lg top-full border-strokeColor bg-whiteColor">
					{React.Children.map(children, (child) => {
						// Ensure only SelectItem components are valid
						if (React.isValidElement<SelectItemProps>(child)) {
							// Enhance child component with props
							const label = child.props.children
								? child.props.children.toString()
								: "";
							return React.cloneElement(child, {
								onSelect: () => handleItemSelect(child.props.value, label),
							});
						}
						return null;
					})}
				</div>
			)}
		</div>
	);
};
