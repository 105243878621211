import OrderStatus from "common/OrderStatus/OrderStatus";

interface OrdersTableProps {
	orders: any;
	onRowClick: (order: any) => void;
}

const OrdersTable: React.FC<OrdersTableProps> = ({ orders, onRowClick }) => {
	console.log("Orders table", orders);
	return (
		<table className="w-full border rounded-lg border-strokeColor">
			<thead className="bg-white border border-strokeColor">
				<tr>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						ID
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Products
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Customer/Phone
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Address
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Status
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Price
					</th>
				</tr>
			</thead>
			<tbody className="text-left">
				{orders.map((order: any, idx: any) => (
					<tr
						key={order.id}
						className={`${
							idx === orders.length - 1
								? "border-0"
								: "border-b border-strokeColor"
						} ${idx % 2 === 0 ? "bg-white" : "bg-[#f9f9f9]"} cursor-pointer`}
						onClick={() => onRowClick(order)}
					>
						<td
							className={`px-5 py-4 text-textColor font-medium ${
								idx === orders.length - 1 ? "rounded-bl-lg" : ""
							}`}
						>
							{order.id}
						</td>
						<td className="px-5 py-4 font-medium text-textColor">
							{order.products
								.map((product: any) => `${product.name}`)
								.join(", ")}
						</td>
						<td className="px-5 py-4">
							<div className="font-medium text-textColor">
								{order.customerName}
							</div>
							<div className="text-passiveTextColor font-regular">
								{order.phoneNumber}
							</div>
						</td>
						<td className="px-5 py-4 text-textColor font-regular">
							{order.address}
						</td>
						<td className="px-5 py-4">
							<OrderStatus status={order.orderStatus} />
						</td>
						<td
							className={`px-5 py-4 text-textColor font-regular ${
								idx === orders.length - 1 ? "rounded-br-lg" : ""
							}`}
						>
							{order.totalPrice} $
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default OrdersTable;
