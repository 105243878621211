import React from "react";
import { ShopCardProps } from "./ShopCard.props";
import Actions from "common/Actions/Actions";
import { P } from "common/P/P";
import { serviceConfig } from "configs";

const ShopCard: React.FC<ShopCardProps> = ({
	imageSrc,
	name,
	onEdit,
	onDelete,
}) => {
	return (
		<div className="grid w-full px-4 py-3 bg-white border rounded-lg shadow-xs grid-cols-maxTwo place-content-between place-items-center border-strokeColor">
			<div className="grid gap-4 grid-cols-maxTwo place-items-center">
				<img
					src={`${serviceConfig.apiUrl}${imageSrc}`}
					alt={name}
					className="object-cover w-16 h-16 rounded-md"
				/>
				<div>
					<P size="p2" type="regular">
						{name}
					</P>
				</div>
			</div>
			<Actions onEdit={onEdit} onDelete={onDelete} />
		</div>
	);
};

export default ShopCard;
