import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { SearchInput } from "common/Search/Search";
import Pagination from "components/Pagination/Pagination";

import ManagePanel from "common/ManagePanel/ManagePanel";
import { Input } from "common/Input/Input";
import { Select } from "common/Select/Select";
import { SelectItem } from "common/Select/SelectItem";
import OrdersTable from "components/OrdersTable/OrdersTable";
import { serviceConfig } from "configs";
import Textarea from "common/Textarea/Textarea";
import formatDate from "utils/formatDate";

const OrdersPage: React.FC = () => {
	const [selectedOrder, setSelectedOrder] = useState<any>(null);
	const [isPanelOpen, setIsPanelOpen] = useState(false);

	const [orderId, setOrderId] = useState("");
	const [searchQuery, setSearchQuery] = useState("");
	const [orders, setOrders] = useState<any>([]);
	const [orderStatus, setOrderStatus] = useState("Pending");
	const [selectedStatus, setSelectedStatus] = useState<string>(orderStatus);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 8;
	const totalPages = Math.ceil(orders.length / itemsPerPage);

	const statusMappings: any = {
		0: "Draft",
		1: "Ordered",
		Pending: "Pending",
		Shipped: "Shipped",
		Delivered: "Delivered",
	};

	const fetchOrders = async () => {
		try {
			const response = await fetch(`${serviceConfig.apiUrl}/orders`);
			const result = await response.json();

			if (result.success) {
				setOrders(result.data);
			} else {
				console.error("Error fetching orders:", result.message);
			}
		} catch (error) {
			console.error("Failed to fetch orders", error);
		}
	};

	useEffect(() => {
		fetchOrders();
	}, []);
	// useEffect(() => {
	// 	if (orders.length > 0) {
	// 		setOrderStatus(orders[0].status);
	// 	}
	// }, [orders]);

	const handleRowClick = (order: any) => {
		console.log(order);

		// Directly use the 'status' property from the order object
		const statusName = statusMappings[order.orderStatus] || "Unknown";
		setSelectedOrder({ ...order, orderStatus: statusName });
		setSelectedStatus(statusName);
		setIsPanelOpen(true);
	};

	const currentOrdersData = orders.map((order: any) => ({
		id: order._id,
		products: order.items.map((item: any) => ({
			name: item.product_name,
			price: item.price,
			totalPrice: item.total_price,
			dimension: item.dimension,
			quantity: item.quantity,
			image: item.product_image,
			realImage: item.product_real_image,
			color: item.color,
		})),
		customerName: order.recipient.name,
		phoneNumber: order.recipient.phone,
		address: order.recipient.address1,
		orderStatus: order.status,
		totalPrice: order.total,
		orderedDate: new Date(order.created),
		status: statusMappings[order.status] || "Unknown",
		gift: order.gift,
		...order,
	}));

	console.log("SELECTED", selectedOrder);
	console.log("SELECTED STATUS", selectedStatus);

	const currentOrders = currentOrdersData.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	const closePanel = () => {
		setIsPanelOpen(false);
		setSelectedOrder(null);
		setSelectedStatus("");
	};

	const reverseStatusMappings = Object.keys(statusMappings).reduce(
		(acc: any, key: any) => {
			const value = statusMappings[key];
			acc[value] = key;
			return acc;
		},
		{}
	);

	const saveChanges = async () => {
		// Find the numeric value or specific key for the selected status label
		const statusKeyForDb =
			reverseStatusMappings[selectedStatus] || selectedStatus;

		try {
			const response = await fetch(`${serviceConfig.apiUrl}/order/status`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					orderId: selectedOrder.id,
					newStatus: statusKeyForDb, // Send the numeric value or specific key to the database
				}),
			});

			const result = await response.json();

			if (result.success) {
				fetchOrders(); // Refresh orders after update
				closePanel();
			} else {
				console.error("Failed to update order status:", result.message);
			}
		} catch (error) {
			console.error("Error updating order status:", error);
		}
	};

	const discardChanges = () => {
		// Implement any logic for discarding changes if needed
		closePanel();
	};

	const handleSearch = async () => {
		if (searchQuery.length === 0) {
			fetchOrders();
			return;
		}

		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/orders/search?q=${searchQuery}`
			);
			const result = await response.json();

			if (result.success) {
				setOrders(result.data); // Update the orders state directly with the search results
			} else {
				console.error("Error fetching search results:", result.message);
			}
		} catch (error) {
			console.error("Failed to fetch search results", error);
		}
	};

	return (
		<div className="p-8">
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					Orders
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					Track, manage and forecast your customers and orders.
				</P>
			</div>
			<div className="grid gap-6">
				<div className="grid mt-8">
					<SearchInput
						placeholder="Search"
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						handleSearch={handleSearch}
					/>
				</div>
				<OrdersTable orders={currentOrders} onRowClick={handleRowClick} />

				<Pagination
					currentPage={currentPage}
					totalPages={totalPages}
					onPageChange={setCurrentPage}
				/>
				{isPanelOpen && (
					<ManagePanel
						isOpen={isPanelOpen}
						onClose={closePanel}
						label="Order Details"
						onSave={saveChanges}
						onDiscard={discardChanges}
					>
						{selectedOrder && (
							<div className="grid gap-4">
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="customer_name"
									label="Customer Name"
									disabled
									value={selectedOrder?.customerName}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="email"
									label="Customer Email"
									disabled
									value={selectedOrder?.email}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="order_id"
									label="ID"
									disabled
									value={selectedOrder?.id}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="invoice"
									label="Invoice"
									disabled
									value={selectedOrder?.invoice}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="phone_number"
									label="Phone"
									disabled
									value={selectedOrder?.phoneNumber}
								/>
								{selectedOrder?.products?.map((product: any, index: number) => (
									<div className="grid gap-1" key={index}>
										<P size="p3" type="medium" className="text-textColor">
											{`Product №${index + 1}`}
										</P>
										<div key={index} className="grid grid-cols-1">
											<Input
												withlabel={true}
												appearance="default"
												type="text"
												name={`product_name_${index}`}
												disabled
												value={product.name}
											/>
										</div>
										<div key={index} className="grid grid-cols-3 gap-2">
											<Input
												withlabel={true}
												appearance="default"
												type="text"
												name={`product_dimension_${index}`}
												disabled
												value={product.dimension ? product.dimension : "N/A"}
											/>
											<Input
												withlabel={true}
												appearance="default"
												type="text"
												name={`product_quantity_${index}`}
												disabled
												value={
													product.quantity ? `${product.quantity} QTY` : "N/A"
												}
											/>
											<Input
												withlabel={true}
												appearance="default"
												type="text"
												name={`product_price_${index}`}
												disabled
												value={`${product.price} $`}
											/>
										</div>
										<div key={index} className="grid gap-4 grid-cols-maxFr">
											<Input
												withlabel={true}
												appearance="default"
												type="text"
												name={`product_color_${index}`}
												disabled
												value={product.color ? product.color : "N/A"}
											/>
											<Input
												withlabel={true}
												appearance="default"
												type="text"
												name={`product_totalPrice_${index}`}
												disabled
												value={
													product.totalPrice ? `${product.totalPrice} $` : "N/A"
												}
											/>
										</div>
										<div className="grid grid-cols-1 gap-2 mt-2" key={index}>
											<P size="p3" type="medium">
												Mockup Image
											</P>
											<img
												src={`${serviceConfig.apiUrl}/${product.image}`}
												alt="mockuped"
												className="w-full h-auto"
											/>
										</div>
										<div className="grid grid-cols-1 gap-2 mt-2" key={index}>
											<P size="p3" type="medium">
												Original Image
											</P>
											<img
												src={`${product.realImage}`}
												alt="real"
												className="w-full h-auto"
											/>
										</div>
									</div>
								))}

								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="total_price"
									label="Total Price"
									disabled
									value={selectedOrder?.totalPrice}
								/>

								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="address"
									label="Address"
									disabled
									value={`${selectedOrder?.country_name}, ${selectedOrder?.city}, ${selectedOrder?.state_name}, ${selectedOrder?.address}`}
								/>

								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="zip"
									label="Zip Code"
									disabled
									value={selectedOrder?.zip}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="gift_from"
									label="Gift from"
									disabled
									value={selectedOrder?.gift.from}
								/>
								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="gift_to"
									label="Gift To"
									disabled
									value={selectedOrder?.gift.to}
								/>
								<Textarea
									value={selectedOrder?.gift.message}
									name="gift_message"
									label="Gift message"
									disabled
									withlabel={true}
								/>

								<Input
									withlabel={true}
									appearance="default"
									type="text"
									name="createdAt"
									label="Ordered Date"
									disabled
									value={formatDate(selectedOrder?.createdAt)}
								/>

								<Select
									onSelect={(value) => {
										const statusName = statusMappings[value]
											? statusMappings[value]
											: value;
										setSelectedStatus(statusName);
									}}
									defaultSelectLabel={
										selectedOrder
											? statusMappings[selectedOrder.status] ||
											  selectedOrder.status
											: "Select Status"
									}
									selectedLabel={selectedStatus}
									label="Status"
								>
									{Object.entries(statusMappings).map(([value, name]: any) => (
										<SelectItem key={value} value={value}>
											{name}
										</SelectItem>
									))}
								</Select>
							</div>
						)}
					</ManagePanel>
				)}
			</div>
		</div>
	);
};

export default OrdersPage;
