import React from "react";

import { PProps } from "./P.props";

export const P = ({
	children,
	size,
	type,
	className,
	...props
}: PProps): JSX.Element => {
	switch (size) {
		case "p1":
			return (
				<p
					className={`text-lg cursor-default ${
						type === "regular"
							? "font-normal"
							: type === "medium"
							? "font-medium"
							: type === "semibold"
							? "font-semibold"
							: type === "bold"
							? "font-bold"
							: ""
					} ${className}`}
					{...props}
				>
					{children}
				</p>
			);
		case "p2":
			return (
				<p
					className={`text-base cursor-default ${
						type === "regular"
							? "font-normal"
							: type === "medium"
							? "font-medium"
							: type === "semibold"
							? "font-semibold"
							: type === "bold"
							? "font-bold"
							: ""
					} ${className}`}
					{...props}
				>
					{children}
				</p>
			);
		case "p3":
			return (
				<p
					className={`text-sm cursor-default ${
						type === "regular"
							? "font-normal"
							: type === "medium"
							? "font-medium"
							: type === "semibold"
							? "font-semibold"
							: type === "bold"
							? "font-bold"
							: ""
					} ${className}`}
					{...props}
				>
					{children}
				</p>
			);
		case "t1":
			return (
				<p
					className={`text-xs cursor-default ${
						type === "regular"
							? "font-normal"
							: type === "medium"
							? "font-medium"
							: type === "semibold"
							? "font-semibold"
							: type === "bold"
							? "font-bold"
							: ""
					} ${className}`}
					{...props}
				>
					{children}
				</p>
			);
		default:
			return <></>;
	}
};
