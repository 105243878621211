import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";

import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { Button } from "common/Button/Button";
import ManagePanel from "common/ManagePanel/ManagePanel";
import ArticleCard from "components/ArticleCard/ArticleCard";
import ImageUpload from "common/Image/ImageUpload";
import { Input } from "common/Input/Input";
import Textarea from "common/Textarea/Textarea";
import Modal from "common/Modal/Modal";
import { serviceConfig } from "configs";

type BlogArticle = {
	_id: string;
	name: string;
	image: string;
	description: string;
	keywords: string[];
};

const initialFormValues = {
	name: "",
	description: "",
	imageSrc: undefined,
	keywords: [],
};

const BlogArticlesPage: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [blogs, setBlogs] = useState<BlogArticle[]>([]);
	const [uploadedImageFile, setUploadedImageFile] = useState<any>(undefined);

	const [editingArticle, setEditingArticle] = useState<any>(null);
	const [deletingArticle, setDeletingArticle] = useState<any>(null);

	const [formValues, setFormValues] = useState<{
		name: string;
		description: string;
		imageSrc?: string;
		keywords: string[];
	}>(initialFormValues);

	const handleOpen = () => {
		setFormValues(initialFormValues);
		setIsOpen(true);
	};
	const handleClose = () => setIsOpen(false);

	useEffect(() => {
		fetchBlogs();
	}, []);

	const handleDiscard = () => {
		console.log("Changes discarded");
		handleClose();
	};

	const fetchBlogs = async () => {
		try {
			const res = await fetch(`${serviceConfig.apiUrl}/blog`);
			const data = await res.json();
			if (data.success) {
				// Assuming the blogs are returned in a "data" property
				setBlogs(data.data);
			} else {
				console.error(data.message);
			}
		} catch (error) {
			console.error("Error fetching blogs:", error);
		}
	};

	const handleSave = async () => {
		try {
			const formData = new FormData();
			formData.append("name", formValues.name);
			formData.append("description", formValues.description);
			formData.append("keywords", formValues.keywords.join(","));
			if (uploadedImageFile) {
				formData.append("image", uploadedImageFile);
			}
			console.log("IMAGE", uploadedImageFile);

			const requestOptions = {
				method: editingArticle ? "PUT" : "POST",
				body: formData,
			};
			const url = editingArticle
				? `${serviceConfig.apiUrl}/blog/${editingArticle._id}`
				: `${serviceConfig.apiUrl}/blog`;

			const res = await fetch(url, requestOptions);
			const data = await res.json();

			if (data.success) {
				fetchBlogs();
				handleClose();
			} else {
				console.error(data.message);
			}
		} catch (error) {
			console.error("Error saving blog:", error);
		}
	};

	const handleDelete = async (articleId: any) => {
		try {
			const res = await fetch(`${serviceConfig.apiUrl}/blog/${articleId}`, {
				method: "DELETE",
			});
			const data = await res.json();

			if (data.success) {
				fetchBlogs();
				setDeletingArticle(null);
			} else {
				console.error(data.message);
			}
		} catch (error) {
			console.error("Error deleting blog:", error);
		}
	};
	return (
		<div className="grid gap-8 p-8">
			<div className="grid grid-cols-maxTwo place-content-between place-items-center">
				<div className="grid gap-1">
					<Htag tag="h2" type="medium">
						Blog/Articles
					</Htag>
					<P size="p2" type="regular" className="text-passiveTextColor">
						Track, manage and forecast your customers and orders.
					</P>
				</div>
				<Button appearance="black" icon={<PlusIcon />} onClick={handleOpen}>
					Add article
				</Button>
			</div>
			<div className="grid gap-4">
				{blogs.map((blog) => (
					<ArticleCard
						key={blog._id}
						imageSrc={blog.image}
						name={blog.name}
						onEdit={() => {
							setEditingArticle(blog);
							setFormValues({
								name: blog.name,
								description: blog.description,
								imageSrc: blog.image,
								keywords: blog.keywords,
							});
						}}
						onDelete={() => setDeletingArticle(blog)}
					/>
				))}
			</div>
			<ManagePanel
				isOpen={isOpen}
				onClose={handleClose}
				label="Add Article"
				onSave={() => handleSave()}
				onDiscard={handleDiscard}
			>
				<div className="grid gap-6">
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="name"
						label="Article Name"
						placeholder="Enter article name..."
						value={formValues.name}
						onChange={(e) =>
							setFormValues((prev) => ({ ...prev, name: e.target.value }))
						}
					/>
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="keywords"
						label="Keywords"
						placeholder="Enter keywords separated by commas..."
						value={formValues.keywords.join(", ")} // Join array into string for display
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								keywords: e.target.value.split(",").map((k) => k.trim()), // Split string into array
							}))
						}
					/>
					<Textarea
						withlabel={true}
						appearance="default"
						name="description"
						label="Article Description"
						placeholder="Enter article description..."
						value={formValues.description}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								description: e.target.value,
							}))
						}
					/>
					<ImageUpload
						singleUpload={true}
						onImageUpload={setUploadedImageFile}
					/>
				</div>
			</ManagePanel>

			{editingArticle && (
				<ManagePanel
					isOpen={!!editingArticle}
					onClose={() => {
						setEditingArticle(null);
						setFormValues(initialFormValues);
					}}
					label="Edit Article"
					onSave={() => {
						if (editingArticle) {
							handleSave();
							setEditingArticle(false);
						}
					}}
					onDiscard={() => setEditingArticle(null)}
				>
					<div className="grid gap-6">
						<Input
							withlabel={true}
							appearance="default"
							type="text"
							name="name"
							label="Article Name"
							placeholder="Enter article name..."
							value={formValues.name}
							onChange={(e) =>
								setFormValues((prev) => ({ ...prev, name: e.target.value }))
							}
						/>
						<Input
							withlabel={true}
							appearance="default"
							type="text"
							name="keywords"
							label="Keywords"
							placeholder="Enter keywords separated by commas..."
							value={formValues.keywords.join(", ")} // Join array into string for display
							onChange={(e) =>
								setFormValues((prev) => ({
									...prev,
									keywords: e.target.value.split(",").map((k) => k.trim()), // Split string into array, trimming each keyword
								}))
							}
						/>
						<Textarea
							withlabel={true}
							appearance="default"
							name="description"
							label="Article Description"
							placeholder="Enter article description..."
							value={formValues.description}
							onChange={(e) =>
								setFormValues((prev) => ({
									...prev,
									description: e.target.value,
								}))
							}
						/>
						<ImageUpload
							singleUpload={true}
							onImageUpload={setUploadedImageFile}
						/>
					</div>
				</ManagePanel>
			)}

			{deletingArticle && (
				<Modal
					isOpen={!!deletingArticle}
					onClose={() => setDeletingArticle(null)}
					title="Delete Article"
					desc={`Are you sure you want to delete this article? This action cannot be undone.`}
					onConfirm={() => {
						handleDelete(deletingArticle._id);
					}}
					onCancel={() => setDeletingArticle(null)}
				/>
			)}
		</div>
	);
};

export default BlogArticlesPage;
