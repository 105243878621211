import { Navigate, Route, Routes } from "react-router-dom";

import { routeConstants } from "navigation";
import DashboardPage from "pages/DashboardPage/DashboardPage";
import UserManagementPage from "pages/UserManagementPage/UserManagementPage";
import UserManagementDetailPage from "pages/UserManagementDetailPage/UserManagementDetailPage";
import OrdersPage from "pages/OrdersPage/OrdersPage";
import DesignDashboardPage from "pages/DesignDashboardPage/DesignDashboardPage";
import BlogArticlesPage from "pages/BlogArticlesPage/BlogArticlesPage";
import FAQ from "pages/FAQ/FAQ";
import CategoriesPage from "pages/CategoriesPage/CategoriesPage";
import DesignList from "pages/DesignList/DesignList";
import ShopListPage from "pages/ShopListPage/ShopListPage";
import ShopMockupPage from "pages/ShopMockupPage/ShopMockupPage";
import MockupedProductsPage from "pages/MockupedProductsPage/MockupedProductsPage";
import SubscribePage from "pages/SubscribePage/SubscribePage";
import ShopCategoriesPage from "pages/ShopCategoriesPage/ShopCategoriesPage";

export const UserRoutes = () => {
	return (
		<Routes>
			<Route
				path={routeConstants.dashboard.route}
				element={<DashboardPage />}
			/>
			<Route
				path={routeConstants.userManagement.route}
				element={<UserManagementPage />}
			/>
			<Route
				path={`${routeConstants.userManagementDetails.route}/:id`}
				element={<UserManagementDetailPage />}
			/>
			<Route
				path={routeConstants.orderManagement.route}
				element={<OrdersPage />}
			/>
			<Route
				path={routeConstants.designDashboard.route}
				element={<DesignDashboardPage />}
			/>
			<Route path={routeConstants.blogs.route} element={<BlogArticlesPage />} />
			<Route path={routeConstants.faq.route} element={<FAQ />} />
			<Route
				path={routeConstants.categories.route}
				element={<CategoriesPage />}
			/>
			<Route path={routeConstants.designList.route} element={<DesignList />} />
			<Route path={routeConstants.shopList.route} element={<ShopListPage />} />
			<Route
				path={routeConstants.shopMockup.route}
				element={<ShopMockupPage />}
			/>
			<Route
				path={routeConstants.shopCategories.route}
				element={<ShopCategoriesPage />}
			/>
			<Route
				path={routeConstants.mockupedProducts.route}
				element={<MockupedProductsPage />}
			/>
			<Route
				path={routeConstants.subscribe.route}
				element={<SubscribePage />}
			/>

			<Route path="*" element={<Navigate to={routeConstants.root.route} />} />
		</Routes>
	);
};
