import React from "react";
import { handleImageError, handleLoadingImage } from "modules/errors";
import { ReactComponent as DeleteIcon } from "common/assets/icons/trash.svg";

interface IImage {
	src: string;
	alt?: string;
	className?: string;
	imageType?: string;
	onDelete?: any;
	slider?: boolean;
}

export const Image: React.FC<IImage> = ({
	src = "",
	alt = "",
	className = "",
	imageType = "default",
	onDelete,
	slider,
}) => {
	return (
		<div
			className={`relative ${
				slider ? "w-full" : "w-[200px]"
			} h-[310px] cursor-pointer group ${className}`}
		>
			<img
				src={src}
				alt={alt}
				onLoad={handleLoadingImage}
				onError={(e: any) => handleImageError(e, imageType)}
				className="object-cover w-full h-full"
			/>
			<div className="absolute inset-0 flex items-center justify-center transition-all duration-200 opacity-0 group-hover:opacity-100">
				<div className="w-full h-full absolute group-hover:bg-gradient-to-t from-[#00000078] to-[#00000030] bg-opacity-40 group-hover:bg-lightgray"></div>
				<div
					className="p-2 bg-white bg-opacity-50 rounded-full backdrop-blur-md"
					onClick={onDelete}
				>
					<DeleteIcon className="w-6 h-6 text-black" />
				</div>
			</div>
		</div>
	);
};
