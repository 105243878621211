import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { Button } from "common/Button/Button";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Input } from "common/Input/Input";
import Textarea from "common/Textarea/Textarea";
import Modal from "common/Modal/Modal";
import DesignCard from "components/DesignCard/DesignCard";
import ImageUpload from "common/Image/ImageUpload";
import StyleManagerItem from "common/StyleManagerItem/StyleManagerItem";
import { SearchInput } from "common/Search/Search";
import { serviceConfig } from "configs";
import ImageUploadModal from "components/ImageUploadModal/ImageUploadModal";

type Design = {
	_id: number;
	name: string;
	image: string;
	prompt: string;
	negativePrompt: string;
	model: string;
	categories: string[];
};

type Category = {
	_id: number;
	name: string;
};

const ShopMockupPage: React.FC = () => {
	const [designs, setDesigns] = useState<Design[]>([]);
	const [categoryData, setCategoryData] = useState<Category[]>([]);
	const [isManagePanelOpen, setIsManagePanelOpen] = useState(false);
	const [currentDesign, setCurrentDesign] = useState<any | null>(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deletingDesignId, setDeletingDesignId] = useState<number | null>(null);
	const [uploadedImageSrc, setUploadedImageSrc] = useState<
		string | undefined
	>();
	const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
	const [formValues, setFormValues] = useState({
		name: "",
		prompt: "",
		negativePrompt: "",
		modelType: "",
	});
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);

	const handleSearch = async () => {
		if (searchQuery.length === 0) {
			// If the search input is empty, fetch all designs
			fetch(`${serviceConfig.apiUrl}/design`)
				.then((res) => res.json())
				.then((data) => {
					if (data.success) {
						setDesigns(data.data);
					} else {
						console.error(data.message);
					}
				})
				.catch((error) => console.error("Error fetching designs:", error));
			return;
		}

		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/design/search?q=${searchQuery}`
			);
			const result = await response.json();

			if (result.success) {
				setDesigns(result.data); // Update the designs state directly with the search results
			} else {
				console.error("Error fetching search results:", result.message);
			}
		} catch (error) {
			console.error("Failed to fetch search results", error);
		}
	};

	return (
		<div className="grid gap-8 p-8">
			<div className="grid grid-cols-maxTwo place-content-between place-items-center">
				<div className="grid gap-1">
					<Htag tag="h2" type="medium">
						Create Mockup
					</Htag>
					<P size="p2" type="regular" className="text-passiveTextColor">
						Track, manage and forecast your designs and orders.
					</P>
				</div>
				<Button appearance="black" icon={<PlusIcon />} onClick={openModal}>
					Upload Image
				</Button>

				<ImageUploadModal
					isOpen={isModalOpen}
					onClose={closeModal}
					// ... other props you need to pass
				/>
			</div>
		</div>
	);
};

export default ShopMockupPage;
