import React from "react";
import { P } from "common/P/P";
import "./Textarea.css";
import { TextareaProps } from "./Textarea.props";

const Textarea = ({
	appearance = "default",
	label,
	className,
	value,
	name,
	placeholder,
	onChange,
	withlabel,
	maxLength,
	minLength,
	rows = 10,
	cols = 50,
	disabled,
	...props
}: TextareaProps): JSX.Element => {
	return (
		<div className={`grid gap-1 ${className}`}>
			{withlabel && label && (
				<label>
					<P size="p3" type="medium" className="text-textColor">
						{label}
					</P>
				</label>
			)}

			<textarea
				value={value}
				name={name}
				onChange={onChange}
				{...props}
				className={`textarea w-full h-full resize-none px-4 py-3 border border-strokeColor rounded-lg hover:border-mainColor focus:border-mainColor transition-all duration-300 ${
					appearance === "error" &&
					"error text-redColor border-redColor hover:border-redColor focus:border-redColor focus-visible:border-redColor"
				}`}
				placeholder={placeholder}
				maxLength={maxLength}
				minLength={minLength}
				rows={rows}
				cols={cols}
				disabled={disabled}
			/>

			{appearance === "withHint" && (
				<span className="inline-block mt-1.5 text-xs font-normal text-passiveTextColor leading-5">
					This is a hint text to help user.
				</span>
			)}

			{appearance === "error" && (
				<span className="inline-block mt-1.5 text-xs font-normal text-redColor leading-5">
					This field is a required field.
				</span>
			)}
		</div>
	);
};

export default Textarea;
