import React from "react";
import { OrderStatusProps } from "./OrderStatus.props";

const OrderStatus: React.FC<OrderStatusProps> = ({ status }) => {
	switch (status) {
		case "Pending":
			return (
				<button className="px-2 py-1 font-medium bg-[#FFFAEB] rounded-2xl text-[#FE9A04]">
					Pending
				</button>
			);
		case "Shipped":
			return (
				<button className="bg-[#FFF6ED] px-2 py-1 font-medium rounded-2xl text-[#C4320A]">
					Shipped
				</button>
			);
		case "Delivered":
			return (
				<button className="bg-[#ECFDF3] px-2 py-1 font-medium rounded-2xl text-[#28C76F]">
					Delivered
				</button>
			);
		case "1":
			return (
				<button className="px-2 py-1 font-medium bg-[#FFFAEB] rounded-2xl text-[#FFC107]">
					Ordered
				</button>
			);
		case "0":
			return (
				<button className="px-2 py-1 font-medium bg-[#FFFAEB] rounded-2xl text-[#9E9E9E]">
					Draft
				</button>
			);
		default:
			return null;
	}
};

export default OrderStatus;
