import React from "react";
import { FAQCardProps } from "./FAQCard.props";
import Actions from "common/Actions/Actions";
import { Input } from "common/Input/Input";
import Textarea from "common/Textarea/Textarea";

const FAQCard: React.FC<FAQCardProps> = ({
	question,
	answer,
	onEdit,
	onDelete,
	id,
}) => {
	return (
		<div className="grid w-[488px] p-6 border rounded-lg shadow-sm border-strokeColor grid-cols-1">
			<div className="grid grid-cols-1 gap-4 place-items-start">
				<div className="grid w-full gap-4 grid-cols-frMax place-items-center">
					<Input
						withlabel={true}
						label={`Question ${id}`}
						appearance="default"
						type="text"
						value={question}
						disabled={true}
						placeholder="Question"
						name="question"
						className="w-full"
					/>
					<Actions onEdit={onEdit} onDelete={onDelete} className="mt-5" />
				</div>
				<Textarea
					withlabel={true}
					label="Answer"
					rows={2}
					appearance="default"
					value={answer}
					disabled={true}
					placeholder="Answer"
					name="answer"
					onChange={function (e: React.ChangeEvent<HTMLTextAreaElement>): void {
						throw new Error("Function not implemented.");
					}}
				/>
			</div>
		</div>
	);
};

export default FAQCard;
