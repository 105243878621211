import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";

import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { Button } from "common/Button/Button";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Input } from "common/Input/Input";
import Textarea from "common/Textarea/Textarea";
import Modal from "common/Modal/Modal";
import FAQCard from "components/FAQCard/FAQCard";
import { serviceConfig } from "configs";

const mockFAQs = [
	{
		id: 1,
		question: "What is FAQ?",
		answer: "FAQ stands for Frequently Asked Questions.",
	},
	{
		id: 2,
		question: "How does this work?",
		answer: "The system processes the requests and returns responses.",
	},
	{
		id: 3,
		question: "Is my data safe?",
		answer: "Yes, we prioritize the security of user data.",
	},
	{
		id: 4,
		question: "How do I reset my password?",
		answer: "Click on the 'Forgot Password' link and follow the instructions.",
	},
	{
		id: 5,
		question: "Can I get a refund?",
		answer: "Please refer to our refund policy for detailed information.",
	},
];

type FAQType = {
	_id: string;
	question: string;
	answer: string;
};

const FAQ: React.FC = () => {
	const [faqs, setFaqs] = useState<FAQType[]>([]);
	const [isManagePanelOpen, setIsManagePanelOpen] = useState(false);
	const [currentFAQ, setCurrentFAQ] = useState<FAQType | null>(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deletingFAQId, setDeletingFAQId] = useState<string | null>(null);
	const [question, setQuestion] = useState<string>("");
	const [answer, setAnswer] = useState<string>("");

	useEffect(() => {
		fetch(`${serviceConfig.apiUrl}/faq`)
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setFaqs(data.data);
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error fetching FAQs:", error));
	}, []);

	const handleAddFAQ = () => {
		setQuestion("");
		setAnswer("");
		setCurrentFAQ(null);
		setIsManagePanelOpen(true);
	};

	const handleEditFAQ = (faq: FAQType) => {
		setQuestion(faq.question);
		setAnswer(faq.answer);
		setCurrentFAQ(faq);
		setIsManagePanelOpen(true);
	};

	const handleDeleteFAQ = (faqId: string) => {
		setDeletingFAQId(faqId);
		setIsDeleteModalOpen(true);
	};

	const confirmDeleteFAQ = () => {
		fetch(`${serviceConfig.apiUrl}/faq/${deletingFAQId}`, {
			method: "DELETE",
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setFaqs(faqs.filter((faq) => faq._id !== deletingFAQId));
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error deleting FAQ:", error));

		setIsDeleteModalOpen(false);
	};

	const handleSave = () => {
		const method = currentFAQ ? "PUT" : "POST";
		const url = currentFAQ
			? `${serviceConfig.apiUrl}/faq/${currentFAQ._id}`
			: `${serviceConfig.apiUrl}/faq`;

		fetch(url, {
			method: method,
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				question: question,
				answer: answer,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					if (currentFAQ) {
						setFaqs((prevFaqs) =>
							prevFaqs.map((faq) =>
								faq._id === currentFAQ._id ? data.data : faq
							)
						);
					} else {
						setFaqs((prevFaqs) => [data.data, ...prevFaqs]);
					}
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error saving FAQ:", error));

		setIsManagePanelOpen(false);
	};

	return (
		<div className="grid gap-8 p-8">
			<div className="grid grid-cols-maxTwo place-content-between place-items-center">
				<div className="grid gap-1">
					<Htag tag="h2" type="medium">
						FAQ
					</Htag>
					<P size="p2" type="regular" className="text-passiveTextColor">
						Track, manage and forecast your customers and orders.
					</P>
				</div>
				<Button appearance="black" icon={<PlusIcon />} onClick={handleAddFAQ}>
					Add FAQ
				</Button>
			</div>
			<div className="grid gap-4 lg:grid-cols-maxTwo 2xl:grid-cols-maxThree">
				{faqs.map((faq) => (
					<FAQCard
						key={faq._id}
						id={faq._id}
						question={faq.question}
						answer={faq.answer}
						onEdit={() => handleEditFAQ(faq)}
						onDelete={() => handleDeleteFAQ(faq._id)}
					/>
				))}
			</div>
			<ManagePanel
				isOpen={isManagePanelOpen}
				onClose={() => setIsManagePanelOpen(false)}
				label={currentFAQ ? "Edit FAQ" : "Add FAQ"}
				onSave={handleSave}
				onDiscard={() => setIsManagePanelOpen(false)}
			>
				<div className="grid gap-6">
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="question"
						label="Question"
						placeholder="Enter question..."
						value={question}
						onChange={(e) => setQuestion(e.target.value)}
					/>
					<Textarea
						withlabel={true}
						appearance="default"
						name="answer"
						label="Answer"
						placeholder="Enter answer..."
						value={answer}
						onChange={(e) => setAnswer(e.target.value)}
					/>
				</div>
			</ManagePanel>
			<Modal
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
				title="Delete FAQ"
				desc="Are you sure you want to delete this FAQ? This action cannot be undone."
				onConfirm={confirmDeleteFAQ}
				onCancel={() => setIsDeleteModalOpen(false)}
			/>
		</div>
	);
};

export default FAQ;
