const _apiPrefix: string = process.env.REACT_APP_API_PREFIX ?? "/api/v1";
const _apiHostUrl: string =
	process.env.REACT_APP_API_HOST_URL ?? "http://127.0.0.1:2424";

export const serviceConfig = {
	apiHost: _apiHostUrl,
	apiUrl: `${_apiHostUrl}${_apiPrefix}`,
	useMockApi: process.env.REACT_APP_USE_MOCK_API
		? parseInt(process.env.REACT_APP_USE_MOCK_API)
		: 1,

	routes: {
		admin_login: process.env.REACT_APP_API_ADMIN_LOGIN ?? "/admin/login",
	},
};
