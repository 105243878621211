import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";

import { ReactComponent as MediaIcon } from "common/assets/icons/media-image-list.svg";
import { ReactComponent as ListIcon } from "common/assets/icons/list.svg";
import { ReactComponent as MonitorIcon } from "common/assets/icons/modern-tv.svg";
import { serviceConfig } from "configs";

const DashboardPage: React.FC = () => {
	const [designCount, setDesignCount] = useState(0);
	const [categoryCount, setCategoryCount] = useState(0);

	useEffect(() => {
		fetch(`${serviceConfig.apiUrl}/design-count`)
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setDesignCount(data.data);
				} else {
					console.error(data.message);
				}
			});

		fetch(`${serviceConfig.apiUrl}/category-count`)
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setCategoryCount(data.data);
				} else {
					console.error(data.message);
				}
			});
	}, []);
	return (
		<div className="grid gap-8 p-8">
			<div className="grid gap-1 grid-cols-frMax">
				<div>
					<Htag tag="h2" type="medium">
						Dashboard
					</Htag>
					<P size="p2" type="regular" className="text-passiveTextColor">
						Track, manage and forecast your customers and orders.
					</P>
				</div>
			</div>
			<div className="grid w-full gap-8 p-6 rounded-md shadow-lightGray">
				<Htag tag="h4" type="medium">
					Design statistics
				</Htag>
				<div className="grid grid-flow-col gap-32 auto-cols-max">
					<div className="grid gap-5 grid-cols-maxTwo place-items-center">
						<MediaIcon />
						<div className="grid gap-1">
							<Htag tag="h4" type="medium">
								{designCount}
							</Htag>
							<P size="p2" type="regular" className="text-passiveTextColor">
								Number of designs
							</P>
						</div>
					</div>
					<div className="grid gap-5 grid-cols-maxTwo place-items-center">
						<ListIcon />
						<div className="grid gap-1">
							<Htag tag="h4" type="medium">
								{categoryCount}
							</Htag>
							<P size="p2" type="regular" className="text-passiveTextColor">
								Number of categories
							</P>
						</div>
					</div>
					{/* <div className="grid gap-5 grid-cols-maxTwo place-items-center">
						<MonitorIcon />
						<div className="grid gap-1">
							<Htag tag="h4" type="medium">
								1.423k
							</Htag>
							<P size="p2" type="regular" className="text-passiveTextColor">
								Number of models
							</P>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default DashboardPage;
