import React, { useState } from "react";
import { ArticleCardProps } from "./ArticleCard.props";
import Actions from "common/Actions/Actions";
import { P } from "common/P/P";
import { serviceConfig } from "configs";

const ArticleCard: React.FC<ArticleCardProps> = ({
	imageSrc,
	name,
	onEdit,
	onDelete,
}) => {
	const [isToggleActive, setIsToggleActive] = useState(true);
	const handleToggle = (active: boolean) => {
		console.log("Toggled", active);
		setIsToggleActive(active);
		// Make your API call here
	};

	return (
		<div className="grid w-full px-4 py-3 bg-white border rounded-lg shadow-xs grid-cols-maxTwo place-content-between place-items-center border-strokeColor">
			<div className="grid gap-4 grid-cols-maxTwo place-items-center">
				<img
					src={`${serviceConfig.apiUrl}${imageSrc}`}
					alt={name}
					className="object-cover w-16 h-16 rounded-md"
				/>
				<P size="p2" type="medium">
					{name}
				</P>
			</div>
			<Actions
				// isActive={isToggleActive}
				// onToggle={handleToggle}
				onEdit={onEdit}
				onDelete={onDelete}
			/>
		</div>
	);
};

export default ArticleCard;
