import formatDate from "utils/formatDate";

interface SubscribeTableProps {
	data: any;
	onRowClick?: (order: any) => void;
	className?: string;
}

const SubscribeTable: React.FC<SubscribeTableProps> = ({
	data,
	onRowClick,
	className,
}) => {
	return (
		<table
			className={`w-full border rounded-lg border-strokeColor ${
				className && className
			}`}
		>
			<thead className="bg-white border border-strokeColor">
				<tr>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						ID
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Email
					</th>
					<th className="px-5 py-4 font-medium text-left border-b text-passiveTextColor border-strokeColor">
						Subscribed Date
					</th>
				</tr>
			</thead>
			<tbody className="text-left">
				{data.map((item: any, idx: any) => (
					<tr
						key={item._id}
						className={`${
							idx === data.length - 1
								? "border-0"
								: "border-b border-strokeColor"
						} ${idx % 2 === 0 ? "bg-white" : "bg-[#f9f9f9]"} cursor-pointer`}
						onClick={() => onRowClick && onRowClick(item)}
					>
						<td
							className={`px-5 py-4 text-textColor font-medium ${
								idx === data.length - 1 ? "rounded-bl-lg" : ""
							}`}
						>
							{item._id}
						</td>
						<td className="px-5 py-4 font-medium text-textColor">
							{item.email}
						</td>
						<td className="px-5 py-4 font-medium text-textColor">
							{formatDate(item.date)}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default SubscribeTable;
